@import url(master.css);

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/lato-light.woff2) format('woff2'),
        url(../fonts/lato-light.woff) format('woff'),
        url(../fonts/lato-light.ttf) format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/latoLight2.woff2) format('woff2'),
        url(../fonts/latoLight2.woff) format('woff'),
        url(../fonts/latoLight2.ttf) format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/latoRegular.woff2) format('woff2'),
        url(../fonts/latoRegular.woff) format('woff'),
        url(../fonts/latoRegular.ttf) format('ttf');
    font-display: swap;
}


@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/latoRegular2.woff2) format('woff2'),
        url(../fonts/latoRegular2.woff) format('woff'),
        url(../fonts/latoRegular2.ttf) format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/lato-semiBold.woff2) format('woff2'),
        url(../fonts/lato-semiBold.woff) format('woff'),
        url(../fonts/lato-semiBold.ttf) format('ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/lato-bold.woff2) format('woff2'),
        url(../fonts/lato-bold.woff) format('woff'),
        url(../fonts/lato-bold.ttf) format('ttf');
    font-display: swap;
}

a {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    text-decoration: none;
}

a,
a:active,
a:focus,
a:hover {
    outline: none;
    text-decoration: none;
    color: var(--color-heading);

}

/* ---header css beginning here--- */
header {
    background: var(--white-color);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 15;
    margin: 0;
    padding: 0 5%;
    border-bottom: solid 1px rgb(2 111 165/0.2);
    transition: all 0.35s ease-in-out;
}

header .header-flex {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}

header .items-logo {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

header .items-logo a {
    display: block;
}

header .items-logo img {
    width: 100%;
    height: 37px;
}

header .items-logo img.hoverImg {
    display: none;
}

.navigation .menuBtnIcon {
    margin: 0;
    padding: 0;
}

.navigation .menuBtnIcon button {
    width: 42px;
    cursor: pointer;
    padding: 4px;
    background: transparent;
    border: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navigation .menuBtnIcon button span {
    font-size: 12px;
    color: var(--secondary-color);
    font-weight: 600;
    margin-top: 6px;
}

@media (min-width: 992.5px) {
    .navigation .menuBtnIcon {
        display: none;
    }
}

/* ---Transparent header beginning here-- */
.transparentHeader {
    background-color: transparent;
    border-bottom: solid 1px #616161;
}

.transparentHeader .navigation ul li a {
    color: var(--white-color);
}

.transparentHeader .items-logo img.currentImg {
    display: none;
}

.transparentHeader .items-logo img.hoverImg {
    display: block;
}

.transparentHeader .navigation ul li.dropDown>a::after {
    border-left: 6px solid var(--white-color);
}

.transparentHeader .menuBtnIcon button span {
    color: var(--white-color);
}

.transparentHeader .menuBtnIcon button svg path {
    fill: var(--white-color);
}

@media (min-width: 992.5px) {
    .transparentHeader:hover {
        background-color: var(--white-color);
    }

    .transparentHeader:hover .items-logo img.currentImg {
        display: block;
    }

    .transparentHeader:hover .items-logo img.hoverImg {
        display: none;
    }

    .transparentHeader:hover .navigation ul li a {
        color: var(--secondary-color);
    }

    .transparentHeader:hover .navigation ul li.dropDown>a::after {
        border-left: 6px solid var(--secondary-color);
    }
}

/* ---Transparent header end here-- */


.openModal {
    overflow: hidden;
}

.siteBackdrop {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
}


/* ---mobile navigation beginning here-- */

.mobileMenuList {
    background-color: var(--white-color);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 21;
    transition: all linear 200ms;
    display: flex;
    flex-direction: column;
}

.mobileMenuList .menu-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    padding: 12px 16px;
}

.mobileMenuList .menu-header a {
    display: flex;
    align-items: center;
}

.mobileMenuList .menu-header a img {
    width: 120px;
}

.mobileMenuList .menu-header button {
    border: 0;
    background: transparent;
    border-radius: 30px;
    width: 46px;
    margin-right: -10px;
}

.mobileMenuList .menu-header button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.menu-list {
    flex: 1;
    margin: 0;
    padding: 10px 16px;
    overflow-y: auto;
}

.menu-list ul {
    margin: 0;
    padding: 0;
}

.menu-list ul li {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.menu-list ul li:not(:last-child) {
    border-bottom: solid 1px rgb(0 0 0/.1);
}

.menu-list ul li a {
    font-size: 16px;
    color: var(--secondary-color);
    line-height: 1.5;
    display: block;
    margin: 0;
    padding: 20px 0px;
    outline: 0;
}

.menu-list ul li a:hover {
    color: #2b2b2b;
    text-decoration: none;
}

.menu-list ul li ul {
    display: none;
    transition: all 0.3s ease 0s;
    margin: 0;
    padding: 0;
}

.menu-list ul li ul li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: relative;
}

.menu-list ul li ul li a::before {
    content: "";
    width: 8px;
    height: 8px;
    background: url("../images/icons/arrow-forward-icon.svg");
    transition: all 0.5s;
    position: absolute;
    left: 6px;
    top: 50%;
    margin-top: -4px;
}


.menu-list ul li ul li a {
    font-size: 15px;
    color: var(--secondary-color);
    line-height: 1.5;
    display: block;
    margin: 0;
    padding: 10px;
    padding-left: 24px;
    outline: 0;
}

.menu-list ul li.subNav a {
    position: relative;
}

.menu-list ul li.subNav>a::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--orange-color);
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -3px;
    transition: all 0.35s ease-in-out;
}

.menu-list ul li a.active {
    position: relative;
    background-color: rgba(0, 0, 0, 0.04);
}

.menu-list ul li a.active::after {
    transform: rotate(180deg);
}

.menu-list ul li a.active~ul {
    display: block;
}




.items-footer {
    padding: 15px 16px;
}

.items-footer .btn-blue {
    width: 100%;
}

.mobileFooterMenu {
    display: flex;
    justify-content: center;
    font-size: 10px;
    color: var(--orange-color);
    padding-bottom: 12px;
}

.mobileFooterMenu a {
    font-size: 10px;
    color: var(--orange-color);
    padding: 0 5px;
}

.mobileFooterMenu p {
    font-size: 10px;
    margin-bottom: 0;
    order: 1;
}

.mobileFooterMenu .itemList {
    display: flex;
    order: 2;
}

@media (max-width: 390px) {
    .mobileFooterMenu {
        flex-direction: column;
        align-items: center;
    }

    .mobileFooterMenu p {
        order: 2;
    }

    .mobileFooterMenu p span {
        display: none;
    }

    .mobileFooterMenu .itemList {
        order: 1;
        margin-bottom: 12px;
    }
}

.items-footer .btnWrapper {
    border-bottom: solid 1px rgb(0 0 0/.5);
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.items-footer h3 {
    text-align: center;
    font-size: 14px;
}

.items-footer .btn-orange {
    width: 100%;
}

/* ---mobile navigation end here-- */

/* ************---old---*************** */

.navigation {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
}

@media (max-width: 992px) {
    header {
        padding: 0;
    }
}

.backdrop {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
}

.navigation>ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex
}

@media (max-width: 992px) {
    .navigation>ul {
        display: none;
    }
}

.navigation ul li {
    display: block;
    position: relative;
}

.navigation ul li>ul {
    display: none;
}

.navigation ul li a {
    display: block;
    text-decoration: none;
    /* white-space: nowrap; */
    color: var(--secondary-color);
    line-height: 1.5;
    font-size: 16px;
    font-weight: 600;
    padding: 28px 24px;
    position: relative;
    border-radius: 0px;
}

.navigation ul li.dropDown a {
    padding-right: 32px;
}

.navigation>ul>li>a:hover {
    color: var(--primary-color);
    /* background: var(--blue-dark); */
}

.navigation ul li a.active {
    border-radius: 0px;
    /* color: var(--orange-color); */
    position: relative;
}

/* .navigation ul li a.active::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--orange-color);
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -6px;
    transition: all 0.35s ease-in-out;
} */

.navigation ul li.dropDown>a::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid var(--secondary-color);
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -4px;
    transition: all 0.35s ease-in-out;
}

.navigation ul li.dropDown:hover>a::after {
    border-left: 6px solid var(--orange-color);
    transform: rotate(90deg);
}

.navigation ul li a.active::after {
    border-left: 6px solid var(--orange-color);
}

/* 
.navigation ul li a.active::after {
    color: var(--white-color);
} */

.navigation ul li>ul {
    /* position: absolute;
    min-width: 170px; */
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    box-shadow: 0 900px 0px 900px rgb(0 0 0/0.6);
}

/* .navigation ul li:hover>ul {
    display: block;
} */

.navigation ul>li>ul {
    border: 1px solid rgba(0, 0, 0, .15);
    border-top: 0;
    background: var(--white-color);
    border-radius: 0px;
    z-index: 10;
    border-top: solid 1px rgb(2 111 165/0.2);
}

.navigation ul li>ul>li {
    width: 100%;
    min-height: 400px;
    padding: 0 5%;
}

/* .navigation ul li li a {
    color: var(--white-color);
    border: 0;
    padding: 10px 15px;
    border-radius: 0;
} */

.navigation li:hover>a {
    color: var(--orange-color) !important;
}

/* .navigation ul li:hover li a:hover {
    background-color: var(--primary-color);
    border: 0;
} */

/* .navigation ul ul ul {
    left: 100%;
    top: 0;
} */


/* ************************ */

.circleBg {
    position: relative;
}

/* .circleBg::after {
    content: "";
    width: 196px;
    height: 98px;
    background: url(../images/menu-bg-circle.svg);
    position: absolute;
    right: 6%;
    bottom: 0;
    opacity: 0.6;
    z-index: -1;
} */

.menu-lg-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 30px 15px 30px 15px;
}

@media (min-width: 1600px) {
    .menu-lg-container {
        max-width: 1420px;
    }
}

.menu-lg-container ul {
    margin: 0;
    padding: 0;
}

.menu-lg-container ul li a {
    font-weight: 400;
    border: 0;
    padding: 6px 0px !important;
    border-radius: 0;
    position: relative;
}

.menu-lg-container ul.subNav li a {
    font-size: 14px;
    color: var(--text-color);
    font-weight: normal;
}

.menu-lg-container ul li a:hover {
    background-color: transparent;
    color: var(--primary-color);
}

.menu-lg-container ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--primary-color);
    transition: 0.4s;
    position: absolute;
    left: 0;
    bottom: 0;
}

.menu-lg-container ul li a:hover::after {
    width: 50%
}

a.link-md {
    display: unset !important;
    font-size: 20px !important;
    color: var(--primary-color) !important;
    font-weight: 600 !important;
    padding: 10px 40px 10px 0px !important;
    position: relative;
}

a.link-md::after {
    content: '';
    display: block;
    width: 24px;
    height: 18px;
    background: url("../images/icons/arrow-right-orange-icon.svg");
    transition: 0.4s;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    right: 8px;
}

a.link-md:hover:after {
    right: 0;
}

.btn-link-flex {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.btn-link-flex button {
    background: var(--card-color);
    border: solid 1px rgba(0 0 0/.1);
    width: auto;
    height: 38px;
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 30px;
    display: flex;
    align-items: center;
    transition: all 0.35s ease-in-out;
    padding: 5px 15px;
    margin-bottom: 15px;
}

.btn-link-flex button:hover {
    background: var(--primary-color);
    color: var(--white-color);
    border: solid 1px var(--primary-color);
}

.btn-link-flex button:not(:last-child) {
    margin-right: 15px;
}


button.blue-light {
    background: var(--card-color);
    border: solid 1px rgba(0 0 0/.1);
    width: auto;
    height: 42px;
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 30px;
    display: flex;
    align-items: center;
    transition: all 0.35s ease-in-out;
    padding: 5px 15px;
    margin-bottom: 15px;
}

button.blue-light:hover {
    background: var(--primary-color);
    color: var(--white-color);
    border: solid 1px var(--primary-color);
}




/* --------------------- */
.menuLink a {
    width: 100%;
    display: unset !important;
    padding: 0 !important;
}

.nav-card {
    background: var(--white-color);
    width: 100%;
    height: 200px;
    display: flex;
    align-content: flex-end;
    background-size: cover !important;
    transition: transform .2s;
    position: relative;
}

.nav-card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0 0 0/.60);
    position: absolute;
    right: 0;
    top: 0;
}

.nav-card>div {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 16px 54px 16px 20px;
}

.nav-card>div::after {
    content: "";
    width: 24px;
    height: 18px;
    background: url(../images/icons/arrow-right-orange-icon.svg);
    position: absolute;
    right: 20px;
    bottom: 16px;
    transition: all 0.35s ease-in-out;
}

.nav-card:hover>div::after {
    right: 12px;
}

.nav-card h2 {
    font-size: 20px;
    color: var(--white-color);
    font-weight: 600;
    position: relative;
    margin-bottom: 12px;
}

.nav-card h2::after {
    content: "";
    width: 30px;
    height: 2px;
    background: var(--orangeLight-color);
    position: absolute;
    left: 0;
    bottom: -5px;
}

.nav-card p {
    font-size: 14px;
    color: var(--white-color);
    margin-bottom: 0;
}

.nav-large-text {
    font-size: 25px !important;
    text-align: center;
}

.nav-large-text span {
    color: var(--orange-color);
}


/*--- Service menu backgrounds ---*/
.servMenuBg1 {
    /* background-image: url(../images/serv-menu-bg-1.webp), url(../images/serv-menu-bg-1.png); */
    background-image: url(../images/serv-menu-bg-1.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.servMenuBg2 {
    background-image: url(../images/serv-menu-bg-2.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.servMenuBg3 {
    background-image: url(../images/serv-menu-bg-3.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.servMenuBg4 {
    background-image: url(../images/serv-menu-bg-4.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.servMenuBg5 {
    background-image: url(../images/serv-menu-bg-5.webp);
    background-repeat: no-repeat;
    background-position: center center;
}


/*--- About menu backgrounds ---*/
.aboutMenuBg1 {
    background-image: url(../images/about-menu-bg-1.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.aboutMenuBg2 {
    background-image: url(../images/about-menu-bg-2.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.aboutMenuBg3 {
    background-image: url(../images/about-menu-bg-3.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.aboutMenuBg4 {
    background-image: url(../images/about-menu-bg-4.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.aboutMenuBg5 {
    background-image: url(../images/about-menu-bg-5.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

/*--- Technology menu backgrounds ---*/
.techMenuBg1 {
    background-image: url(../images/tech-menu-bg-1.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.techMenuBg2 {
    background-image: url(../images/tech-menu-bg-2.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.techMenuBg3 {
    background-image: url(../images/tech-menu-bg-3.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.techMenuBg4 {
    background-image: url(../images/tech-menu-bg-4.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.techMenuBg5 {
    background-image: url(../images/tech-menu-bg-5.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.techMenuBg6 {
    background-image: url(../images/tech-menu-bg-6.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

/* --------------------- */
/* ******************************* */

.navigation .btnOrange {
    background: #ffa000;
    background: -webkit-linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    background: -o-linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    background: linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    transition: 0.5s;
    background-size: 200% auto;
    padding: 10px 20px;
    height: 100%;
    border: 0;
    border-radius: 0;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation .btnOrange:hover {
    background-position: right center;
}

.navigation .btnOrange img {
    margin-left: 10px;
}

@media (min-width: 992.5px) {
    .navigation .btnOrange img {
        animation: upLeftRight 1.2s linear infinite alternate-reverse;
    }
}

@keyframes upLeftRight {
    0% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(8px);
    }
}

@media (max-width: 992.5px) {
    .navigation .btnOrange .webIcon {
        display: none;
    }

    .navigation .btnOrange img {
        margin-left: 0px;
    }

    .navigation .btnOrange {
        margin-right: 10px;
    }
}

@media (min-width: 992.5px) {
    .navigation .btnOrange .mobileIcon {
        display: none;
    }
}

@media (max-width: 360px) {
    .navigation .btnOrange {
        padding: 10px;
    }

    .navigation .btnOrange {
        margin-right: 5px;
    }
}

@media (max-width: 1100px) {
    .navigation ul li a {
        padding: 28px 15px;
    }

    .navigation .btn-blue {
        padding: 10px 8px;
        margin-left: 6px;
    }
}

.itemRight ul {
    right: 0px !important;
}

.topMargin {
    margin-top: 80px;
}

/* @media only screen and (max-width: 1280px) and (min-width: 992px) {
    .header-nav nav ul li {
        padding: 0 10px;
    }   
} */

@media (max-width: 992px) {
    header .header-flex {
        padding: 0px;
        height: 72px;
    }

    .topMargin {
        margin-top: 72px;
    }
}

/* ---slider css beginning here-- */

video {
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768.5px) {
    .onlyForWeb {
        display: none;
    }
}

@media (min-width: 768.5px) {
    .onlyForMobile {
        display: none;
    }
}

.section-slider {
    padding: 0px 0px 20px 0px;
}

/* .section-slider .carousel-item {
     display: none; 
} */

.section-slider .slider-container {
    max-width: 100%;
    position: relative;
    margin: auto;
}

.inner-wrapper {
    background: #fff url(../images/carousel-bg.svg) no-repeat left top;
    padding: 40px 0px 20px 0px;
}

.inner-wrapper>div {
    padding: 0 5%
}

.inner-wrapper .content-flex {
    display: flex;
}

.inner-wrapper .content-flex .items-content {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    order: 1;
    padding-right: 20px;
}

.inner-wrapper .content-flex .items-content h1,
.inner-wrapper .content-flex .items-content h2 {
    font-size: 48px;
    color: var(--primary-color);
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 12px;
}

.inner-wrapper .content-flex .items-content h1 span,
.inner-wrapper .content-flex .items-content h2 span {
    font-weight: 600;
}


.inner-wrapper .content-flex .items-img {
    width: 70%;
    order: 2;
}

.inner-wrapper .content-flex .items-img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.section-carousel-wp {
    padding: 0 5%;
}

.carosal-btn-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 1600px) {
    .carosal-btn-row {
        max-width: 1420px;
        padding: 0;
        margin: 0 auto;
    }
}

.carosal-btn-row .carousel-indicators {
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.carosal-btn-row .carousel-indicators .dot {
    background: var(--white-color);
    border: solid 4px #7097ab;
    cursor: pointer;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    transition: 0.6s ease;
    opacity: .5;
    margin-right: 8px;
}

.carosal-btn-row .carousel-indicators .dot:last-child {
    margin-right: 0;
}

.carosal-btn-row .carousel-indicators .active {
    background: var(--primary-color) !important;
    border: solid 4px var(--primary-color) !important;
    background-clip: unset !important;
    opacity: 1;
}

.carosal-btn-row .btn-arrow {
    display: flex;
    padding-right: 15px;
}

.carosal-btn-row .btn-arrow .next {
    margin-left: 15px;
}

.carosal-btn-row .btn-arrow button {
    cursor: pointer;
    color: var(--white-color);
    font-weight: 300;
    font-size: 18px;
    transition: 0.6s ease;
    background-color: var(--primary-color);
    border-radius: 30px;
    width: 36px;
    height: 36px;
    padding: 5px;
    border: 0;
    opacity: .5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carosal-btn-row .btn-arrow button:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .carosal-btn-row {
        padding: 0 15px;
    }

    .carosal-btn-row .btn-arrow {
        padding-right: 0px;
    }

    .carosal-btn-row .carousel-indicators {
        padding-left: 0px;
    }

}

@media (max-width: 576px) {
    .carosal-btn-row .btn-arrow {
        display: none;
    }

    .carosal-btn-row {
        justify-content: center;
    }

    .carosal-btn-row .carousel-indicators .dot {
        margin-right: 16px;
    }
}

.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@media only screen and (max-width: 300px) {

    .prev,
    .next,
    .text {
        font-size: 11px
    }
}





/* .carosal-btn-row .btn-arrow{} */

@media only screen and (max-width:1250px) and (min-width:992px) {
    .inner-wrapper .content-flex .items-content {
        width: 40%;
        padding-right: 15px;
    }

    .inner-wrapper .content-flex .items-img {
        width: 60%;
    }

}


@media (max-width: 992px) {
    .section-slider .slider-container .container-main {
        padding: 0;
    }

    .inner-wrapper .content-flex {
        width: 100%;
        flex-wrap: wrap;
        padding: 0;
    }

    .inner-wrapper {
        padding: 0;
    }

    .inner-wrapper>div {
        padding: 0px;
    }

    .inner-wrapper .container-fluid {
        padding: 0;
    }

    .inner-wrapper .content-flex .items-content {
        width: 100%;
        order: 2;
        padding: 20px 15px 30px 15px;
        align-items: center;
    }

    .inner-wrapper .content-flex .items-content h1,
    .inner-wrapper .content-flex .items-content h2,
    .inner-wrapper .content-flex .items-content p {
        text-align: center;
    }

    .inner-wrapper .content-flex .items-img {
        width: 100%;
        order: 1;
    }

    .inner-wrapper .content-flex .items-img img {
        width: 100%;
        border-radius: 0;
    }

}

@media (max-width: 576px) {

    .inner-wrapper .content-flex .items-content h1,
    .inner-wrapper .content-flex .items-content h2 {
        font-size: 36px;
    }

    .section-slider {
        padding: 0px 0px 30px 0px;
    }

}


.for-mobile {
    display: none;
}

@media (max-width: 576px) {
    .for-web {
        display: none;
    }

    .for-mobile {
        display: block;
    }
}




/* ********************************** */


.container-main {
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
}

/* @media (min-width: 768px){
    .container-main{
        max-width: 720px;
    }
}
@media (min-width: 992px){
    .container-main{
        max-width: 960px;
    }
} */
/* @media (min-width: 1200px){
    .container-main{
        max-width: 1140px;
    }
}
@media (min-width: 1400px){
    .container-main{
        max-width: 1360px;
    }
} */

@media (min-width: 1600px) {
    .container-main {
        max-width: 1420px;
    }
}

.row-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.row-flex .items-col-1 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.row-flex .items-col-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-3 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-4 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-5 {
    -ms-flex: 0 0 41.66%;
    flex: 0 0 41.66%;
    max-width: 41.66%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-7 {
    -ms-flex: 0 0 58.33%;
    flex: 0 0 58.33%;
    max-width: 58.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-8 {
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .custom-col-55 {
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
    padding-right: 10px;
    padding-left: 10px;
}

.row-flex .custom-col-45 {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
    padding-right: 10px;
    padding-left: 10px;
}





/* column large css beginning here */

.row-flex .items-lg-col-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-lg-col-3 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}


.row-flex .items-lg-col-5 {
    -ms-flex: 0 0 41.66%;
    flex: 0 0 41.66%;
    max-width: 41.66%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.row-flex .items-lg-col-7 {
    -ms-flex: 0 0 58.33%;
    flex: 0 0 58.33%;
    max-width: 58.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}


.row-flex .items-lg-col-8 {
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

@media (max-width:992px) {

    .row-flex .custom-col-55,
    .row-flex .custom-col-45,
    .row-flex .items-lg-col-2,
    .row-flex .items-lg-col-3,
    .row-flex .items-lg-col-5,
    .row-flex .items-lg-col-7,
    .row-flex .items-lg-col-8 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width:768px) {

    .row-flex .items-col-2,
    .row-flex .items-col-3,
    .row-flex .items-col-8,
    .row-flex .items-col-9 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-flex .items-col-4 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}


@media (max-width:576px) {
    .row-flex .items-col-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* ********************************* */


/* globals css beginning here */

h1 {
    font-size: 48px;
    color: var(--secondary-color);
    line-height: 1.2;
    font-weight: 400;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 20px;
}

h1 span {
    color: var(--primary-color);
    font-weight: 700;
}

h1::after {
    content: "";
    width: 40px;
    height: 4px;
    background: var(--orangeLight-color);
    position: absolute;
    left: 0;
    bottom: -5px;
}

@media (max-width:768px) {
    h1 {
        font-size: 40px;
    }
}

h2 {
    color: var(--primary-color);
    font-size: 38px;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 8px;
}

h2.text-black {
    color: var(--secondary-color) !important;
    ;
    margin-bottom: 12px;
}

h2.medium {
    font-size: 24px;
    line-height: normal;
}

h2.large {
    font-size: 38px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 20px;
}

h2.large::after {
    content: "";
    width: 40px;
    height: 4px;
    background: var(--orangeLight-color);
    position: absolute;
    left: 0;
    bottom: -5px;
}

@media (max-width:768px) {

    h2,
    h2.large {
        font-size: 30px;
    }
}

@media (max-width:380px) {
    h1 {
        font-size: 30px;
    }

    h2,
    h2.large {
        font-size: 24px;
    }
}

h3 {
    font-size: 20px;
    color: var(--secondary-color);
    line-height: 1.5;
    font-weight: 600;
    padding: 0;
    margin-bottom: 10px;
}

h3.text-black {
    color: var(--secondary-color) !important;
}

h3.subTitle {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
}

.subTitle {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 15px;
}

p {
    font-size: 16px;
    font-weight: normal;
    color: var(--text-color);
    line-height: 1.75em;
    margin-bottom: 15px;
}

@media (max-width:768px) {
    p {
        font-size: 14px;
    }
}

p a:hover {
    color: var(--primary-color);
    text-decoration: underline;
}

p a.hyperlink {
    color: var(--primary-color);
    text-decoration: none;
}

p a.hyperlink:hover {
    text-decoration: underline;
}

p.medium {
    font-size: 14px;
}

p.large {
    font-size: 18px;
    line-height: 1.8;
}

p.small {
    font-size: 12px;
}

p.heading {
    font-size: 16px;
    color: var(--secondary-color);
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 10px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.bold {
    font-weight: 600;
}

.text-white {
    color: var(--white-color) !important;
}

.table-scroller {
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
}

table.regular {
    /* width: 100%; */
    border-collapse: collapse;
    border: solid 1px rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 0;
}

table.regular td {
    border: solid 1px rgba(0, 0, 0, 0.2);
    text-align: left;
    font-size: 16px;
    color: var(--text-color);
    padding: 8px 10px;
}

table.regular td a:hover {
    color: var(--primary-color);
    text-decoration: underline;
}

table.fullWidth {
    width: 100%;
}

table.fullWidth td {
    width: 50%;
}

table.fullWidth {
    width: 100%;
}

table.halfWidth {
    width: 60%;
}

@media (max-width:576px) {
    table.halfWidth {
        width: 100%;
    }
}


ol.regular {
    margin: 0;
    padding: 0;
    padding-left: 25px;
}

ol.regular li {
    font-size: 16px;
    color: var(--text-color);
    font-weight: 400;
    padding: 0px;
    padding-left: 8px;
    margin-bottom: 8px;
}


.anchor-wp {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.anchor-wp a {
    font-size: 18px;
    color: var(--primary-color) !important;
    line-height: 32px;
    cursor: pointer;
}

.anchor-wp a:hover {
    color: var(--blue-dark) !important;
    text-decoration: underline !important;
}

.forTarget h2::before {
    content: "";
    display: block;
    height: 98px;
    margin: -98px 0 0;
}

.targetContent h1::before,
.targetContent h2::before {
    content: "";
    display: block;
    height: 98px;
    margin: -98px 0 0;
}

@media (max-width:768px) {

    .targetContent h1::before,
    .targetContent h2::before {
        content: "";
        display: block;
        height: 140px;
        margin: -140px 0 0;
    }
}


.bgWhite {
    background: var(--white-color);
}

/* --------- */

ul.regular {
    margin: 0;
    padding: 0;
    padding-left: 25px;
}

ul.regular li {
    font-size: 16px;
    color: var(--text-color);
    font-weight: 400;
    padding: 0px;
    margin-bottom: 8px;
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list li {
    list-style: none;
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.8;
    font-weight: 400;
    padding: 0px 0px 0px 30px;
    margin-bottom: 6px;
    position: relative;
}


.list li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 50%;
    background: var(--orange-color);
    transform: rotate(48deg);
    margin-top: -4px;
}


/* --------- */
.list-red {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
    margin-left: 5px;
}

.list-red li {
    list-style: none;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    padding: 0px 0px 0px 35px;
    margin-bottom: 6px;
    position: relative;
}

.list-red li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 50%;
    background: var(--orange-color);
    transform: rotate(48deg);
    margin-top: -4px;
}

.list-red li span {
    display: block;
    color: var(--primary-color);
    font-size: 16px;
}

.list-red li span a {
    display: block;
    color: var(--primary-color);
    font-size: 16px;
}

.list-red li span a:hover {
    text-decoration: underline;
}

/* --------- */

.list-black {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
}

.list-black>li {
    list-style: none;
    font-size: 16px;
    color: var(--secondary-color);
    font-weight: 400;
    padding: 0px 0px 0px 25px;
    margin-bottom: 6px;
    position: relative;
}

.list-black>li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 13px;
    background: var(--orange-color);
    transform: rotate(48deg);
}

.list-roman {
    margin: 0;
    padding: 0 0 0 24px;
    margin-bottom: 6px;
}

.list-roman li {
    background: none;
    list-style: lower-roman;
    font-size: 16px;
    color: var(--secondary-color);
    font-weight: 400;
    padding: 0px 0px 0px 6px;
    margin-bottom: 6px;
}

ul.large {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.large>li {
    font-size: 24px;
    color: var(--text-color);
    font-weight: 400;
    line-height: 34px;
    padding: 0px;
    padding-left: 20px;
    position: relative;
}

ul.large>li:not(:last-child) {
    margin-bottom: 8%;
}

ul.large>li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 14px;
    background: var(--orange-color);
    transform: rotate(48deg);
}

ul.large>li ul {
    list-style: none;
}

ul.large>li ul li {
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

ul.large>li ul li::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 14px;
    background: transparent;
    border: solid 1px var(--orange-color);
}

@media (max-width:1280px) {
    ul.large>li:not(:last-child) {
        margin-bottom: 15px;
    }
}

/* ------------------ */

ul.bullet {
    list-style: none;
    padding: 0;
}

ul.bullet li {
    color: var(--secondary-color);
    position: relative;
    padding-left: 18px;
    margin-bottom: 12px;
}

ul.bullet li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 13px;
    background: var(--orange-color);
    transform: rotate(48deg);
}

/* ************************** */

.section-content {
    background: var(--cardBg-color);
    width: 100%;
    padding: 140px 5% 120px 5%;
    position: relative;
}

.section-content .indicator-icon {
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -35px;
}

.section-content .text-inner {
    padding-right: 90px;
}

.section-content h2 span {
    display: block;
    animation: blinkingText 4s infinite;
}

@media (max-width:576px) {
    .section-content h2 span {
        display: inline-block;
    }
}

@keyframes blinkingText {
    0% {
        color: #208DE9;
    }

    25% {
        color: #026fa5;
    }

    50% {
        color: #ffa000;
    }

    75% {
        color: #ff6000;
    }

    100% {
        color: #ff6000;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
}

.home-video-wp {
    position: relative;
    width: 692px;
    height: 363px;
}

.home-video-wp::before {
    content: "";
    background: url("../images/video-bg-left.svg");
    position: absolute;
    top: -44px;
    left: -20px;
    width: 252px;
    height: 210px;
}

.home-video-wp::after {
    content: "";
    background: url("../images/video-bg-right.svg");
    position: absolute;
    right: -20px;
    bottom: -38px;
    width: 160px;
    height: 140px;
}


.home-video-wp iframe {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 363px;
    border-radius: 5px;
}

@media (max-width:1366px) {
    .home-video-wp::before {
        top: -20px;
        left: -32px;
    }

    .home-video-wp::after {
        right: -32px;
        bottom: -20px;

    }
}


@media (max-width:992px) {
    .section-content .text-inner {
        padding-right: 30px;
    }

    .section-content h1 span {
        display: inline-block;
    }
}

@media (max-width:768px) {
    .section-content {
        background: var(--cardBg-color);
        width: 100%;
        padding: 80px 0px 60px 0px;
        position: relative;
    }

    .section-content .text-inner {
        padding-right: 0px;
    }

    .home-video-wp {
        width: 100%;
        height: auto;
    }

    .home-video-wp::before {
        display: none;
    }

    .home-video-wp::after {
        display: none;
    }
}

@media (max-width:576px) {
    .home-video-wp iframe {
        height: auto;
        aspect-ratio: 16 / 9;
    }
}



.seeLink {
    font-size: 16px;
    color: var(--primary-color);
    text-decoration: underline;
}



.section-latest-works {
    background: var(--cardBg-color);
    width: 100%;
    /* padding: 140px 5% 120px 5%; */
    padding: 5% 5% 6% 5%;
}

.section-latest-works h3.subTitle {
    max-width: 625px;
}

@media (max-width:768px) {
    .section-latest-works {
        padding: 80px 0px 60px 0px;
    }
}

/* --- buttons new beginning here--- */

.btn-white {
    background-color: var(--white-color);
    border-color: var(--white-color);
    font-size: 16px;
    color: var(--primary-color);
    min-width: 110px;
    height: 48px;
    padding: 10px 25px;
    font-weight: 600;
    border: 0;
}

.btn-white:hover,
.btn-white:focus {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--white-color) !important;
}


.btn-outline-blue {
    background-color: transparent;
    border: solid 2px var(--primary-color);
    font-size: 16px;
    color: var(--primary-color);
    min-width: 110px;
    height: 48px;
    padding: 10px 25px;
    font-weight: 500;
}

.btn-outline-blue:hover,
.btn-outline-blue:focus {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--white-color) !important;
}


.btn-blue {
    background-color: var(--primary-color);
    border: solid 2px var(--primary-color);
    font-size: 16px;
    color: var(--white-color);
    min-width: 110px;
    height: 48px;
    padding: 10px 25px;
    font-weight: 600;
}

.btn-blue:hover,
.btn-blue:focus {
    background-color: transparent !important;
    border: solid 2px var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.btn-100 {
    width: 100%;
}

@media (max-width: 576px) {
    .btn-white {
        width: 100%;
    }
}


.btn-hover {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-hover img {
    margin-left: 6px;
    margin-bottom: -3px;
}

.btn-hover .iconBlue {
    display: none;
}

.btn-hover:hover .iconWhite {
    display: none;
}

.btn-hover:hover .iconBlue {
    display: block;
}

.btn-hover:focus .iconWhite {
    display: none;
}

.btn-hover:focus .iconBlue {
    display: block;
}

@media (max-width: 576px) {
    .btn-hover {
        width: 100%;
    }
}


.btn-orange {
    background: var(--orangeLight-color);
    background-image: -webkit-linear-gradient(to left, var(--orangeLight-color), var(--orange-color), var(--orangeLight-color), var(--orangeLight-color));
    background-image: -o-linear-gradient(to left, var(--orangeLight-color), var(--orange-color), var(--orangeLight-color), var(--orangeLight-color));
    background-image: linear-gradient(to left, var(--orangeLight-color), var(--orange-color), var(--orangeLight-color), var(--orangeLight-color));
    background-position: 50% 0;
    background-size: 300% 100%;
    transition: 0.6s;
    border: 0;
    border-radius: 0;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    height: 48px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 8px 11px 20px rgba(0, 0, 0, 0.25);
    padding: 10px 30px;
}

.btn-orange:hover {
    background-position: 100% 0
}

.btn-orange img {
    margin-left: 10px;
    animation: upLeftRight 1.2s linear infinite alternate-reverse;
}

@keyframes upLeftRight {
    0% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(8px);
    }
}

/* .btn-orange {
    background: #ffa000;
    background: -webkit-linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    background: -o-linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    background: linear-gradient(to right, #ffa000 0%, #ff6000 51%, #ffa000 100%);
    transition: 0.5s;
    background-size: 200% auto;
    padding: 10px 20px;
    height: 100%;
    border: 0;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
  }  
 .btn-orange:hover {
    background-position: right center;
  } */


/* --- buttons new end here--- */


/* --- buttons old beginning here--- */

.latest-btn-style {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    font-size: 16px;
    color: var(--white-color);
    min-width: 110px;
    padding-right: 25px;
    padding-left: 25px;
    font-weight: 400;
}

.latest-btn-style:hover,
.latest-btn-style:focus {
    background: var(--blue-hover);
    border-color: var(--blue-hover);
}

.latest-btn-style-2 {
    border-radius: 30px;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white-color);
    font-size: 16px;
    font-weight: 400;
    min-width: 110px;
    padding-right: 25px;
    padding-left: 25px;
}

.latest-btn-style-2:hover,
.latest-btn-style-2:focus {
    background: transparent;
    border-color: var(--white-color);
}

.latest-btn-style-3 {
    background: transparent;
    border-color: var(--white-color);
    border-radius: 30px;
    font-size: 16px;
    color: var(--white-color);
    font-weight: 400;
    margin-inline: 1%;
    min-width: 110px;
    padding-right: 25px;
    padding-left: 25px;
}

.latest-btn-style-3:hover,
.latest-btn-style-3:focus {
    background: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-outline-light {
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 576px) {
    .btn-outline-light {
        width: 100%;
    }
}


/* --- buttons old end here--- */


.section-digi-exp {
    width: 100%;
    background: var(--cardBg-color);
    padding: 5% 5% 0px 5%;
}

.section-digi-exp .row-flex .items-col-5,
.section-digi-exp .row-flex .items-col-7 {
    margin-bottom: 0;
}

.section-digi-exp p {
    font-size: 22px;
    color: var(--text-color);
}

.section-digi-exp .btn-wrapper {
    margin-bottom: 15px;
}

.items-middle {
    display: flex;
    align-items: center;
}

.digi-exp-img {
    text-align: center;
}

/* .digi-exp-img img {
    max-width: 80%;
} */

.digi-exp-wp {
    position: relative;
}

@media (max-width:992px) {
    .digi-exp-wp {
        display: none;
    }

    .section-digi-exp .row-flex .items-col-7 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .digi-exp-div {
        color: var(--white-color);
        margin: 0;
    }

    .digi-exp-img {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .digi-exp-img img {
        max-width: 92%;
    }

    .section-digi-exp .btn-wrapper {
        margin-bottom: 20px;
    }
}

@media (max-width:768px) {
    .digi-exp-div {
        margin: 0;
        padding: 60px 0px 0px 0px;
    }

    .digi-exp-img {
        margin: 0;
        display: flex;
        justify-content: center;
        padding: 40px 8% 0px 8%;
    }

    .section-digi-exp {
        width: 100%;
        padding: 80px 0px 60px 0px;
    }
}


.section-logos {
    position: relative;
    /* background: #f5f8fa; */
    background-color: var(--white-color);
    width: 100%;
    padding: 5% 5% 5% 5%;
}

.logos-wrapper {
    margin: 0;
    padding: 0;
}

.logos-wrapper ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.logos-wrapper ul li {
    list-style: none;
    width: 20%;
    padding: 40px 15px;
    text-align: center;
}

.logos-wrapper ul li img {
    vertical-align: middle;
}

.logos-wrapper ul li img:hover {
    opacity: .6;
}

@media (max-width:1280px) {
    .logos-wrapper ul li img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width:992px) {

    .logos-wrapper ul li {
        width: 25%;
        padding: 20px 12px;
    }

    .logos-wrapper {
        padding: 0 0 3% 0;
    }
}

@media (max-width:768px) {
    .section-logos {
        padding: 80px 12px 60px 12px;
    }

    .logos-wrapper ul li {
        width: 33%;
    }
}

@media (max-width:480px) {
    .logos-wrapper ul li {
        width: 50%;
    }

    .logos-wrapper ul li {
        padding: 15px 5px;
    }
}

.section-services {
    background: #fff url(../images/serv-bg-bottom.svg) no-repeat bottom left;
    background-size: 100% auto;
    width: 100%;
    /* padding: 140px 5% 120px 5%;  */
    padding: 5% 5% 6% 5%;
}

.section-services h3.subTitle {
    max-width: 625px;
}

.card-services {
    background: var(--white-color);
    height: 100%;
    /* padding: 20px;
    padding-bottom: 45px; */
    border-radius: 15px;
    position: relative;
    transition: transform .2s;
}

.card-services:hover {
    -ms-transform: scale(1.03);
    /* IE 9 */
    -webkit-transform: scale(1.03);
    /* Safari 3-8 */
    transform: scale(1.03)
}

.card-services>a {
    display: block;
    height: 100%;
    padding: 20px;
    padding-bottom: 45px;
    border-radius: 15px;
    cursor: pointer;
}

.servBg1 {
    background-color: #E8FFF7;
    border: solid 1px rgba(22, 100, 71, .20);
}

.servBg2 {
    background-color: #EBFEED;
    border: solid 1px rgba(25, 104, 33, .20);
}

.servBg3 {
    background-color: #F3FFEB;
    border: solid 1px rgba(62, 114, 21, .20);
}

.servBg4 {
    background-color: #FDFFE8;
    border: solid 1px rgba(87, 96, 12, .20);
}

.servBg5 {
    background-color: #FFFAEB;
    border: solid 1px rgba(92, 74, 13, .20);
}

.servBg6 {
    background-color: #EEFFFF;
    border: solid 1px rgba(18, 86, 89, .20);
}


.opacity-60 {
    background: rgba(255, 255, 255, 0.62);
}

.section-services .row-flex .items-col-3 {
    margin-bottom: 10px !important;
}

.card-services .title-flex {
    display: flex;
    margin-bottom: 15px;
}

.card-services .title-cont {
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.card-services h3 {
    font-size: 26px;
    margin-bottom: 0;
}

.card-services .services-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 5px 20px 10px 20px;
    border-radius: 0 0 15px 15px;
}

.card-services .services-link p {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
    padding: 5px 20px 5px 0px;
    border-radius: 30px;
    position: relative;
    margin-right: -10px;
    text-decoration: underline;
    margin: 0;
}

.card-services .services-link p:hover {
    /* color: var(--primary-color); */
    color: var(--secondary-color);
    text-decoration: none;
}

.card-services .services-link p::after {
    content: "";
    width: 7px;
    height: 12px;
    background: url("../images/icons/arrow-right-icon-2.svg");
    transition: all 0.5s;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -4px;
}

.card-services .services-link p:hover::after {
    background: url("../images/icons/arrow-right-black-icon.svg");
    right: 3px;
}

@media only screen and (max-width:1250px) and (min-width:992px) {
    .card-services .items-icon img {
        height: 72px;
    }

    .card-services .title-cont {
        padding-left: 15px;
    }

    .card-services h3 {
        font-size: 2.1vw;
    }

    /* .card-services {
        padding: 20px 15px 45px 15px;
    } */

    .card-services .services-link {
        padding: 5px 15px 10px 15px;
    }
}

@media only screen and (max-width:992px) and (min-width:768px) {
    .card-services .items-icon img {
        height: 52px;
    }

    .card-services .title-cont {
        padding-left: 10px;
    }

    .card-services h3 {
        font-size: 2.5vw;
    }

    /* .card-services {
        padding: 15px 12px 45px 12px;
    } */

    .card-services p {
        font-size: 14px;
    }

    .card-services .services-link {
        padding: 5px 12px 10px 12px;
    }

    .card-services .services-link a {
        font-size: 12px;
    }
}

@media (max-width: 380px) {
    .card-services .title-cont {
        padding-left: 15px;
    }

    .card-services .title-flex img {
        height: 72px;
    }

    .card-services h3 {
        font-size: 24px;
    }

    .card-services>a {
        padding: 15px 15px 45px 15px;
    }

    .card-services .services-link {
        padding: 5px 15px 10px 15px;
    }

    .card-services .services-link a {
        font-size: 13px;
    }
}


/* ---tab css beginning here--- */

.tab-list-wp {
    margin: 0;
    padding: 0;
}

.tab-list-wp ul {
    cursor: pointer;
    font-size: 1.4rem;
    margin-top: 6%;
    list-style: none;
    padding-left: 0;
}

.tab-list-wp ul li {
    color: var(--secondary-color);
    text-decoration: none;
    font-weight: normal;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.tab-list-wp ul li .fa-solid {
    margin-left: 6px;
    margin-bottom: -5px;
}

.tab-list-wp ul li:hover,
.tab-list-wp ul li.active {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 1.4rem;
}

.bg-color-white {
    background: var(--white-color);
}

.tabcontent {
    display: none;
    padding: 0;
}


@media (max-width:768px) {
    .section-services {
        padding: 80px 0px 60px 0px;
    }

    .tab-list-wp ul {
        margin-top: 2%;
    }
}


/* .content-linkedin {
    padding: 1% 3%;

} */

/* .linkedin-logo {
    float: right;
    width: 10%;
    margin-right: 3%;
} */

/*============
  insights
  ==================*/


.section-insights {
    background: var(--cardBg-color);
    width: 100%;
    padding: 5% 5% 5% 5%;
}

.section-insights h3.subTitle {
    max-width: 625px;
}

.card-details {
    background: var(--white-color);
    border: solid 1px var(--border-blue-light);
    border-radius: 5px;
    padding: 24px;
    height: 100%;
    position: relative;
}

.card-details h2 {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 12px;
}

.card-details h3 {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 12px;
}

.card-details h3.blackText {
    color: var(--secondary-color);
}

.card-details p.date {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.card-details img {
    margin-bottom: 10px;
}

.fixed-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 24px 22px 24px;
}

.fixed-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 24px 16px 24px;
}

.card-details .btn-flex {
    display: flex;
    justify-content: flex-end;
}

.card-details .btn-center {
    display: flex;
    justify-content: center;
}

.card-details .btn-flex a.cardLink {
    font-size: 14px;
    font-weight: 600;
    color: var(--secondary-color);
    padding: 5px 20px 5px 10px;
    border-radius: 30px;
    position: relative;
    margin-right: -10px;
    margin-bottom: -8px;
    text-decoration: underline;
}

.card-details .btn-flex a.cardLink:hover {
    color: var(--primary-color);
    text-decoration: none;
}

.card-details .btn-flex a.cardLink::after {
    content: "";
    width: 7px;
    height: 12px;
    background: url("../images/icons/arrow-right-icon-2.svg");
    transition: all 0.5s;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -4px;
}

.card-details .btn-flex a.cardLink:hover::after {
    right: 5px;
}

@media only screen and (max-width:1140px) and (min-width:992.5px) {
    .card-details .fixed-btn .btn-blue {
        font-size: 15px;
        padding: 10px 16px;
    }
}


@media (max-width:768px) {
    .section-insights {
        padding: 5% 0px;
    }

    .card-details {
        padding: 20px 15px;
    }

    .pb-75 {
        padding-bottom: 90px;
    }

    .fixed-btn {
        padding: 0 15px 22px 15px;
    }

    .fixed-link {
        padding: 0 15px 16px 15px;
    }
}

@media (max-width:576px) {
    .card-details .btn-blue {
        width: 100%;
        padding: 10px 5px;
    }
}

@media (max-width:340px) {
    .card-details .btn-blue {
        font-size: 15px;
    }
}


/* ---Footer Css beginning here--- */
footer {
    width: 100%;
    margin: 0;
    padding: 0px 5%;
    position: relative;
}

.footerBg {
    width: 26%;
    height: 56%;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 20px 0 0 0;
    /* background-image: url(../images/building_skillikz.webp), url(../images/building_skillikz.png); */
    background-image: url(../images/building_skillikz.webp);
    background-repeat: no-repeat;
    background-position: center top;
    opacity: .5;
}

@media (max-width: 992px) {
    .footerBg {
        width: 35%;
        height: 25%;
    }
}

@media (max-width: 768px) {
    .footerBg {
        display: none;
    }
}


/* footer::after {
    content: "";
    width: 450px;
    height: 280px;
    width: 26%;
  height: 56%;
    position: absolute;
    border-radius: 20px 0 0 0;
    right: 0;
    bottom: 0;
    background: url(../images/building_skillikz.png) no-repeat;
    background-size: 100% 100%;  
    opacity: .5;
  } */

@media (min-width:992.5px) {
    footer .container-main {
        display: flex;
        justify-content: flex-start;
    }
}

footer .footerLeftContent {
    width: 350px;
    background-color: #F9FBFD;
    padding: 60px 35px 60px 0;
    position: relative;
}

footer .footerLeftContent::before {
    content: "";
    width: 600%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -560%;
    bottom: 0;
    background-color: #F9FBFD;
    z-index: -1;
}


footer .footerLeftContent::after {
    content: "";
    width: 32px;
    height: 80%;
    position: absolute;
    top: 10%;
    right: 0;
    border-radius: 8px;
    border-right: solid 1px var(--primary-color);
}

footer .footerLeftContent h2 {
    font-size: 20px;
    color: var(--blue-dark);
    margin-bottom: 20px;
}

footer .footerLeftContent .btn-orange {
    width: 100%;
}


footer .footerLeftContent .action-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

footer .footerLeftContent .action-wrapper a:first-child {
    font-size: 16px;
    color: var(--primary-color);
    line-height: 1.5;
    display: flex;
    align-items: center;
}

footer .footerLeftContent .action-wrapper a:first-child:hover {
    text-decoration: underline;
}

footer .footerLeftContent .action-wrapper a:first-child img {
    margin-right: 6px;
}

footer .footerLeftContent .action-wrapper a:last-child {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer .footerLeftContent .action-wrapper a:last-child:hover {
    background-color: rgb(2 111 165/.1);
}

footer .footerLeftContent button.btn-blue {
    background: var(--primary-color) url(../images/icons/arrow-right-white-icon2.svg) no-repeat right center;
    width: 100%;
}

footer .footerLeftContent button.btn-blue:hover,
footer .footerLeftContent button.btn-blue:focus {
    background: transparent url(../images/icons/arrow-right-blue-icon2.svg) no-repeat right center;
}

/* ************************ */


footer .footerRightContent {
    flex: 1;
    padding: 60px 0 60px 35px;
}

footer .footerRightContent h2 {
    font-size: 16px;
    color: var(--blue-dark);
    line-height: 1;
    margin-bottom: 10px;
}

footer .footerRightContent ul {
    margin: 0;
    padding: 0;
}

footer .footerRightContent ul li {
    list-style: none;
    padding: 7px 0;
}

footer .footerRightContent ul li a {
    font-size: 14px;
    color: var(--secondary-color);
    line-height: 1.5;
    display: block;
    padding: 2px 0;
    position: relative;
}

footer .footerRightContent ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--orange-color);
    transition: 0.4s;
    position: absolute;
    left: 0;
    bottom: 0;
}

footer .footerRightContent ul li a:hover::after {
    width: 50%
}


footer .footerRightContent .row-flex>div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 60px;
}

footer .row-flex .items-col-4 {
    margin-bottom: 0;
}

footer .footerRightContent button.btn-blue {
    background: var(--primary-color) url(../images/icons/arrow-right-white-icon2.svg) no-repeat right center;
    padding: 10px 20px;
    padding-right: 45px;
}

footer .footerRightContent button.btn-blue:hover,
footer .footerRightContent button.btn-blue:focus {
    background: transparent url(../images/icons/arrow-right-blue-icon2.svg) no-repeat right center;
}


@media only screen and (max-width:1160px) and (min-width:992.5px) {
    footer .footerRightContent button.btn-blue {
        font-size: 14px;
        background-size: 20px 20px;
        padding: 10px 8px;
        padding-right: 25px;
    }

    footer .footerRightContent button.btn-blue:hover,
    footer .footerRightContent button.btn-blue:focus {
        background-size: 20px 20px;
        padding: 10px 8px;
        padding-right: 25px;
    }
}


@media only screen and (max-width:768px) and (min-width:360px) {

    footer .row-flex .items-col-4:first-child,
    footer .row-flex .items-col-4:nth-child(2) {
        margin-bottom: 25px;
    }
}

@media (max-width:768px) {
    .footerRightContent .row-flex .items-col-4 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width:359.5px) {
    .footerRightContent .row-flex .items-col-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 25px;
    }

    footer .footerRightContent button.btn-blue {
        width: 100%;
        margin-top: 20px;

    }

    footer .footerRightContent .row-flex>div:last-child {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width:1199.5px) and (min-width:992.5px) {
    footer .footerLeftContent {
        padding: 60px 20px 60px 0;
    }

    footer .footerRightContent {
        padding: 60px 0 60px 20px;
    }

    footer ul li {
        padding: 5px 0;
    }
}

@media (max-width:992px) {
    footer .footerLeftContent {
        width: auto;
        padding: 60px 15px;
        margin-left: -15px;
        margin-right: -15px;
    }

    footer .footerLeftContent .onlyForTab {
        width: 350px;
        margin: 0 auto;
    }

    footer .footerRightContent {
        flex: unset;
        width: 100%;
        padding: 60px 0 42px 0;
    }

    footer .footerLeftContent::after {
        left: -400%;
    }

    footer .footerLeftContent::before {
        position: unset;
    }
}

@media (max-width:992px) {
    footer {
        padding: 0px;
    }
}

@media (max-width:576px) {
    footer .footerLeftContent .onlyForTab {
        width: 100%;
        margin: 0 auto;
    }

    footer .footerLeftContent .share-icons a {
        margin-left: 0px;
    }
}

/* @media (max-width: 450px) {
    footer .footerLeftContent .share-icons a span {
        display: inline-block;
    }
} */


/* --Footer bottom Nav css beginning here--- */

.footerBottomSection {
    border-top: solid 1px #9CB6CC;
    padding: 16px 5%;
}

.footerBottomSection .copyRightText {
    display: flex;
    align-items: center;
    order: 1;
}

.footerBottomSection .bottomNav {
    order: 2;
}

.footerBottomSection .copyRightText p {
    font-size: 12px;
    color: var(--text-color);
    line-height: 1.5;
    margin: 0;
}

.footerBottomSection .items-col-2 {
    margin-bottom: 0;
}

.footerBottomSection ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
}

.footerBottomSection ul li {
    list-style: none;
    padding: 0px;
}

.footerBottomSection ul li:not(:last-child) {
    margin-right: 20px;
}

.footerBottomSection ul li a {
    font-size: 12px;
    color: var(--text-color);
    line-height: 1.5;
    display: block;
    text-decoration: underline;
    padding: 0;
}

.footerBottomSection ul li a:hover {
    text-decoration: none;
}

@media (max-width:768px) {
    .footerBottomSection {
        border-top: solid 1px #9CB6CC;
        padding: 16px 0px;
    }

    .footerBottomSection .copyRightText {
        justify-content: center;
        order: 2;
        margin-bottom: 5px;
    }

    .footerBottomSection .bottomNav {
        order: 1;
        margin-bottom: 15px;
    }

    .footerBottomSection ul {
        justify-content: center;
    }

    .footerBottomSection ul li:not(:last-child) {
        margin-right: 18px;
    }
}

@media (max-width:380px) {
    .footerBottomSection ul {
        flex-direction: column;
        align-items: center;
    }

    .footerBottomSection ul li:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 12px;
    }

    .footerBottomSection .bottomNav {
        margin-bottom: 20px;
    }
}


.form-group {
    margin: 0;
    padding: 0;
    position: relative;
}

.form-group label {
    display: inline-block;
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 5px;
}

.form-group label span {
    color: var(--red-color);
}

.form-group input,
.form-group select,
.form-group textarea {
    display: block;
    width: 100%;
    font-size: 16px;
    color: var(--text-color);
    font-weight: 400;
    line-height: 1.5;
    background: var(--white-color);
    border: solid 1px var(--border-blue-light);
    border-radius: 5px;
    padding: 8px 12px;
}

.form-group input,
.form-group select {
    height: 48px;
}

.form-group select.dropIcon {
    background: #fff url(../images/icons/arrowDown.svg) no-repeat right center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 38px;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
    color: rgba(15, 27, 33, 0.40);
}

.form-group input.searchIcon {
    background: #fff url(../images/icons/search-icon.svg) no-repeat right center;
    padding-right: 38px;
}

.form-group .error-msg {
    font-size: 13px;
    color: #dc3545;
    position: absolute;
    bottom: -18px;
    left: 0;
    /* display: none; */
}

.form-group .btn-blue {
    width: 100%;
}

.btn-wrapper {
    display: flex;
}

.btn-wrapper button {
    margin-right: 15px;
}

.btn-wrapper button:last-child {
    margin-right: 0;
}

@media (max-width: 576px) {
    .btn-wrapper {
        flex-wrap: wrap;
    }

    .btn-wrapper button {
        margin: 0;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .btn-wrapper button:last-child {
        margin-bottom: 0px;
    }
}

.inner-banner {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}

.inner-banner img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.section-inner-content {
    background: var(--white-color) url(../images/serv-bg-bottom.svg) no-repeat bottom left;
    background-size: 100% auto;
    width: 100%;
    padding: 20px 5% 80px 5%;
    border-bottom: solid 1px rgb(0 0 0/.1);
}

@media (max-width: 768px) {
    .section-inner-content {
        padding: 20px 0px 60px 0px;
    }
}


/* ----Breadcrumb css beginning here---- */
.breadcrumb-wp {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

/* @media (max-width: 480px) {
    .breadcrumb-wp {
      margin-bottom: 10px;
    }
  } */
.breadcrumb-wp .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}

.breadcrumb-wp .breadcrumb li {
    font-size: 12px;
    color: var(--secondary-color);
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.breadcrumb-wp .breadcrumb a {
    font-size: 12px;
    color: var(--primary-color);
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
}

.breadcrumb-wp .breadcrumb a:hover {
    color: var(--secondary-color);
}

.breadcrumb-wp .breadcrumb li {
    position: relative;
    padding-left: 19px;
}

.breadcrumb-wp .breadcrumb li:first-child {
    padding-left: 0px;
}

.breadcrumb-wp .breadcrumb .hyperLink {
    font-size: 12px;
    color: var(--primary-color);
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
}

.breadcrumb-wp .breadcrumb .hyperLink:hover {
    color: var(--secondary-color);
}

.breadcrumb-wp .breadcrumb-item+.breadcrumb-item::before {
    content: "";
    width: 7px;
    height: 12px;
    background: url("../images/icons/arrow-right-black-icon.svg") no-repeat;
    background-size: 5px;
    position: absolute;
    left: 7px;
    top: 6px;
}

.breadcrumb-wp .breadcrumb .active {
    font-weight: 600;
}


.inner-card-services {
    background: var(--card-bg);
    padding: 24px;
    height: 100%;
    position: relative;
}

.inner-card-services h3 {
    font-size: 20px;
    color: var(--secondary-color);
    font-weight: 600;
    margin-bottom: 12px;
}

.inner-card-services p {
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary-color);
    margin-bottom: 0;
}

.inner-card-services p:last-child {
    margin-bottom: 0 !important;
}

.inner-card-services .fixed-link {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 24px 16px 24px;
}

.inner-card-services .fixed-link a {
    font-size: 14px;
    font-weight: 400;
    color: #005783;
    padding: 5px 20px 5px 10px;
    border-radius: 30px;
    position: relative;
    margin-right: -10px;
    margin-bottom: -8px;
    text-decoration: underline;
}

.inner-card-services .fixed-link a:hover {
    color: var(--secondary-color);
    text-decoration: none;
}

.inner-card-services .fixed-link a::after {
    content: "\f105";
    font: normal normal normal 16px/1 FontAwesome;
    color: #005783;
    font-weight: 400;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -7px;
    transition: all 0.5s;
}

.inner-card-services .fixed-link a:hover::after {
    right: 5px;
}

.related-service h2 {
    font-size: 24px;
    color: var(--text-color);
    font-weight: 400;
    margin-bottom: 12px;
}

.related-service .related-card {
    background-size: cover;
    height: 100%;
    border-radius: 5px;
}

.related-service .related-card .bg-wp {
    height: 100%;
    border-radius: 5px;
    padding: 24px;
    padding-bottom: 70px;
    position: relative;
}

@media (max-width: 768px) {
    .inner-card-services {
        padding: 20px 15px;
    }
}

.related-service .related-card h3 {
    color: var(--white-color);
    margin-bottom: 12px;
}

.related-service .related-card p {
    font-size: 16px;
    font-weight: 400;
    color: var(--white-color);
}

.related-service .bg-1 {
    background: rgba(52, 96, 96, .80);
}

.related-service .bg-2 {
    background: rgba(99, 68, 27, 0.80);
}

.related-service .bg-3 {
    background: rgba(80, 99, 27, .80);
}

.related-service .bg-4 {
    background: rgba(14, 86, 2, .80);
}

.related-service .btn-white {
    min-width: 110px;
    padding: 10px 15px;
}

.related-service .btn-flex {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 20px;
    padding-right: 24px;
}


@media (max-width: 576px) {

    .related-service .related-card .bg-wp {
        padding-bottom: 24px;
    }

    .related-service .related-card p {
        margin-bottom: 30px;
    }

    .related-service .btn-flex {
        display: unset;
        justify-content: flex-end;
        position: unset;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 0px;
        padding-right: 0px;
    }
}

.share-on-wp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.share-on-wp .items-download a {
    font-size: 16px;
    color: var(--primary-color);
    padding: 4px 8px;
    border-radius: 30px;
    margin-left: -8px;
}

.share-on-wp .items-download a:hover {
    background: rgba(0, 0, 0, 0.06);
}

.share-on-wp .items-download a img {
    margin-right: 5px;
    transition: all 0.35s ease-in-out;
}

.share-on-wp .items-download a:hover img {
    margin-bottom: -4px;
}

.share-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-icons p {
    font-size: 12px;
    color: var(--secondary-color);
    margin-right: 10px;
    margin-bottom: 0;
}

.share-icons a {
    font-size: 14px;
    color: var(--secondary-color);
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.share-icons a:not(:last-child) {
    margin-right: 15px;
}

.share-icons a span {
    margin-left: 6px;
}

.share-icons a:hover {
    opacity: 0.8;
    text-decoration: underline;
}

@media only screen and (max-width: 991.5px) and (min-width: 768.5px) {
    .share-on-wp .share-icons a span {
        display: none;
    }

    .share-on-wp .share-icons p {
        margin-right: 20px;
    }

    .share-on-wp .share-icons a:not(:last-child) {
        margin-right: 30px;
    }
}

@media (max-width: 768px) {
    .share-on-wp {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .share-on-wp h1 {
        margin-bottom: 15px;
    }

    .share-on-wp .share-icons p {
        margin-right: 20px;
    }

    .share-on-wp .items-download {
        margin-bottom: 15px;
    }

    .share-icons a:not(:last-child) {
        margin-right: 30px;
    }
}

@media (max-width: 450px) {
    .share-icons a {
        font-size: 12px;
    }

    .share-icons a:not(:last-child) {
        margin-right: 20px;
    }

    .share-icons a .fa-brands,
    .share-icons a i {
        margin-right: 0;
    }
}

@media (max-width: 340px) {
    .share-icons a:not(:last-child) {
        margin-right: 12px;
    }
}

@media (min-width: 992.5px) {
    .topShareIcon button.btn-blue {
        width: 100%;
    }
}

@media only screen and (max-width: 1280px) and (min-width: 992.5px) {
    .topShareIcon .share-icons a span {
        display: none;
    }
}

@media (max-width: 992px) {
    .topShareIcon {
        text-align: center;
    }

    .topShareIcon button.btn-blue {
        width: 360px;
    }
}

@media (max-width: 576px) {
    .topShareIcon button.btn-blue {
        width: 100%;
    }
}

@media (max-width: 374.5px) {
    .customShareIcon a span {
        display: none;
    }
}

.pagination {
    margin: 0px;
    margin-bottom: 15px;
}

.pagination ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.pagination ul li {
    list-style: none;
    margin-right: 10px;
}

.pagination ul li:last-child {
    margin-right: 0;
}

.pagination ul li a {
    background-color: var(--white-color);
    width: 36px;
    height: 36px;
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 5px;
    border: solid 1px var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pagination ul li a:hover {
    background-color: rgba(0, 0, 0, 0.08);
    text-decoration: none;
}

.pagination ul li a.active {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.pagination ul li a.first,
.pagination ul li a.last {
    background: var(--white-color);
    width: 48px;
    font-weight: 400;
    color: var(--primary-color);
    border-color: var(--white-color);
}

.pagination ul li a.first:hover,
.pagination ul li a.last:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-color: var(--primary-color);
}

.pagination ul li a.disabled {
    background: var(--white-color);
    pointer-events: none;
}

.pagination ul li a.next,
.pagination ul li a.prev {
    background: var(--primary-color);
    width: 48px;
    font-weight: 400;
    color: var(--white-color);
}

.pagination ul li a.disabled-btn {
    opacity: 0.40;
    pointer-events: none;
}

.title-wp-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.title-wp-flex h2 {
    font-size: 20px;
    font-weight: normal;
    color: var(--text-color);
    margin-bottom: 0;
}

.title-wp-flex .sortBy-group {
    display: flex;
    align-items: center;
}

.title-wp-flex .sortBy-group label {
    font-size: 16px;
    color: var(--secondary-color);
    margin-right: 10px;
}


.title-wp-flex .sortBy-group select {
    background: var(--white-color) url(../images/icons/arrow-down.svg) no-repeat right center;
    font-size: 16px;
    color: var(--secondary-color);
    font-weight: 500;
    border-radius: 5px;
    border: solid 1px var(--border-blue-light);
    padding: 5px 8px 8px 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 30px;
    height: 48px;
}

@media (max-width: 450px) {
    .title-wp-flex {
        flex-wrap: wrap;
    }

    .title-wp-flex>div {
        width: 100%;
    }

    .title-wp-flex .itemsLeft {
        margin-bottom: 15px;
    }

    .title-wp-flex .sortBy-group select {
        flex: 1;
    }
}

@media (min-width: 768.5px) {
    .stickyWp {
        position: sticky;
        top: 90px;
        padding-right: 20px;
    }
}


.tableOfContent-wp {
    margin-bottom: 20px;
    padding: 0;
}

.tableOfContentMbl {
    display: none;
    position: sticky;
    top: 73px;
    margin: 0;
    background-color: var(--card-color);
    border: solid 1px rgb(0 0 0/.2);
    border-radius: 4px;
    z-index: 3;
}

.tableOfContent-wp p,
.tableOfContentMbl p {
    font-weight: 600;
    margin-bottom: 5px;
}

.listOfContent {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
    margin-left: 5px;
}

.listOfContent li {
    list-style: none;
    position: relative;
}

.listOfContent li::before {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../images/icons/arrow-right-blue.svg");
    /* transform: rotate(48deg); */
    margin-top: -6px;
}

.listOfContent li a {
    display: block;
    font-size: 16px;
    color: var(--primary-color);
    line-height: 1.5;
    padding: 5px 0 5px 20px;
    cursor: pointer;
}

.listOfContent li a:hover {
    text-decoration: underline;
}

.blogsOrder .items-col-3 {
    order: 1;
}

.blogsOrder .items-col-8 {
    order: 2;
}

@media (max-width: 768px) {

    .tableOfContent-wp {
        display: none;
    }

    .tableOfContentMbl {
        display: block;
    }

    .blogsOrder .items-col-3 {
        order: 2;
    }

    .blogsOrder .items-col-8 {
        order: 1;
    }

}


/* aanand */








@media (max-width: 768px) {
    .leftContainer {
        padding-right: 0px;
    }
}

.options-wrapper .btn-blue {
    width: 100%;
}

.options-wrapper .list-red li {
    padding-left: 0;
}

.options-wrapper .list-red li a {
    display: block;
    font-size: 16px;
    color: var(--primary-color);
    line-height: 1.5;
    padding: 5px 0 5px 20px;
}

.options-wrapper .list-red li:last-child {
    margin-bottom: 0;
}

.options-wrapper .list-red li a:hover {
    text-decoration: underline;
}

.options-wrapper button {
    width: 100%;
    display: unset;
    text-align: center;
}

.options-wrapper .share-icons {
    justify-content: flex-start
}

@media only screen and (max-width: 991.5px) and (min-width: 768.5px) {
    .options-wrapper .share-icons a span {
        display: none;
    }

    .options-wrapper .share-icons a:not(:last-child) {
        margin-right: 30px;
    }
}


/* .contact-card {
    background: var(--contact-card-bg);
    padding: 20px;
    height: 100%;
} */

.contact-card p {
    margin-bottom: 0;
}

.contact-card a {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 400;
    text-decoration: underline;
}

.contact-card a:hover {
    opacity: 0.8;
    text-decoration: none;
}

.contact-card a img {
    margin-right: 6px;
}

.contact-card .form-group {
    margin-bottom: 15px;
}

.tabcontent {
    display: none;
}

.location-tab-list {
    margin: 0;
    padding: 0;
}

.location-tab-list ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.location-tab-list ul li {
    list-style: none;
    border-radius: 10px 10px 0px 0px;
    background: transparent;
    font-size: 16px;
    color: var(--primary-color);
    padding: 8px 10px;
    text-align: center;
    margin-right: 4px;
    cursor: pointer;
    min-width: 110px;
}

.location-tab-list ul li:last-child {
    margin-right: 0;
}

.location-tab-list ul li:hover {
    background: var(--contact-card-bg);
    color: var(--secondary-color);
}

.location-tab-list ul .active {
    background: var(--contact-card-bg);
    color: var(--secondary-color);
    font-weight: 600;
}

@media (max-width: 768px) {
    .location-tab-list {
        overflow-y: hidden;
        overflow-x: auto;
    }

    .location-tab-list ul {
        width: 100%;
    }

    .location-tab-list ul li {
        flex: 0 0 auto;
    }
}

.location-content {
    background: var(--contact-card-bg);
    margin: 0;
    padding: 25px;
    margin-bottom: 25px;
}

.location-content h2 {
    font-size: 24px;
    color: var(--text-color);
    font-weight: 400;
    margin-bottom: 12px;
}

.location-content .map-wrapper {
    border: solid 1px rgba(0, 0, 0, .10);
    border-radius: 5px;
}

.location-content .map-wrapper iframe {
    width: 100%;
    height: 450px;
    border-radius: 5px;
}

.location-content .location-link {
    font-size: 16px;
    color: var(--primary-color);
    text-decoration: underline;
}

.location-content .location-link span {
    padding: 0 10px;
}

.location-content .location-link:hover {
    opacity: 0.8;
    text-decoration: none;
}

.location-content .location-link .fa-solid {
    font-size: 18px;
}

.contact-card .share-icons a {
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-color);
    text-decoration: underline;
}

.contact-card .share-icons a:hover {
    text-decoration: none;
}

.contact-card .share-icons a:not(:last-child) {
    margin-right: 20px;
}

.contact-card .share-icons a .fa-brands {
    font-size: 26px;
    color: var(--primary-color);
    margin-right: 8px;
}

.share-icons a:hover .fa-brands {
    opacity: 0.8;
}

@media only screen and (max-width:1270px) and (min-width:768.5px) {
    .contact-card .share-icons a span {
        display: none;
    }
}

@media (max-width: 768px) {
    .location-content {
        padding: 25px 15px;
    }

    .location-content .map-wrapper {
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .location-content .btn-blue {
        width: 100%;
    }
}

@media (max-width: 480px) {

    .contact-card .share-icons a:not(:last-child) {
        margin-right: 30px;
    }

    .contact-card .share-icons a .fa-brands {
        margin-right: 5px;
    }
}



.view-flex {
    background: rgba(112, 151, 171, .10);
    padding: 20px 20px 50px 20px;
    position: relative;
    height: 100%;
}

.view-flex h2 {
    font-size: 24px;
    color: var(--text-color);
    font-weight: 600;
    margin-bottom: 20px;
}

.view-flex p {
    line-height: 28px;
}

.view-flex .link-wp {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 5px 20px 20px 0px;
}

.view-flex .link-wp a {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
    line-height: 1.5;
    padding: 5px 20px 5px 10px;
    border-radius: 30px;
    position: relative;
    margin-right: -5px;
    text-decoration: underline;
}

.view-flex .link-wp a:hover {
    text-decoration: none;
}

.view-flex .link-wp a::after {
    content: "";
    width: 7px;
    height: 12px;
    background: url("../images/icons/arrow-right-icon-2.svg");
    transition: all 0.5s;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -4px;
}

.view-flex .link-wp a:hover::after {
    right: 5px;
}

.view-flex .itemsImg {
    margin-bottom: 15px;
}

.view-flex .itemsImg img {
    border-radius: 4px;
    border: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 576px) {
    .view-flex {
        padding: 15px 15px 50px 15px;
    }
}







.card-mission {
    background-color: #327ca1;
    padding: 25px 20px;
    height: 100%;
    border-radius: 5px;
}

.cardBg2 {
    background-color: #2b6a88;
}

.cardBg3 {
    background-color: #255e7a;
}

.cardBg4 {
    background-color: #1a506a;
}

.cardBg5 {
    background-color: #114158;
}

.cardBg6 {
    background-color: #062f43;
}

.cardBg7 {
    background-color: #052738;
}

.card-mission .title-flex {
    display: flex;
    margin-bottom: 12px;
}

.card-mission .item-content {
    display: flex;
    align-items: center;
    padding-left: 12px;
}

.card-mission h2 {
    font-size: 20px;
    color: var(--white-color);
    margin-bottom: 0;
}

.card-mission p {
    color: var(--white-color);
}

.card-mission p:last-child {
    margin-bottom: 0;
}

/* ----------------------- */
.card-working {
    background: rgba(2, 111, 165, .08);
    padding: 25px 20px;
    height: 100%;
    border-radius: 5px;
}

.card-working h3 {
    font-size: 24px;
    color: var(--blue-dark);
}

.card-working p:last-child {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .card-working {
        padding: 20px 15px;
    }
}

.tabs-flex {
    display: flex;
}

.tabs-flex .tabs-list {
    width: 164px;
    margin: 0;
}

.tabs-flex .tabs-list ul {
    margin: 0;
    padding: 0;
}

.tabs-flex .tabs-list ul li {
    font-size: 16px;
    color: var(--blue-dark);
    list-style: none;
    padding: 4px 10px 4px 20px;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
}

.tabs-flex .tabs-list ul li:hover {
    background: rgba(0, 0, 0, .02);
}

.tabs-flex .tabs-list ul li::after {
    content: "";
    position: absolute;
    top: 16px;
    left: 0;
    height: 2px;
    width: 12px;
    background: var(--blue-dark);
}

.tabs-flex .tabs-list ul li.active {
    font-weight: 600;
    background: rgba(0, 0, 0, .02);
}

.tabs-flex .tabs-list ul li.active::after {
    top: 15px;
    height: 4px;
}

.tabs-flex .tabs-conten {
    flex: 1;
}

@media (max-width: 768px) {
    .tabs-flex {
        flex-wrap: wrap;
    }

    .tabs-flex .tabs-list {
        width: 100%;
        margin-bottom: 10px;
    }

    .tabs-flex .tabs-conten {
        flex: unset;
        width: 100%;
    }
}


.card-inner-tabs {
    background: var(--contact-card-bg);
    height: 100%;
    border-radius: 5px;
    position: relative;
    padding: 25px 20px;
    padding-bottom: 45px;
}

.card-inner-tabs h3 {
    font-size: 24px;
    color: var(--blue-dark);
}

.card-inner-tabs span {
    background-color: #b0d6f0;
    font-size: 12px;
    color: var(--secondary-color);
    line-height: 1.5;
    border-radius: 4px;
    padding: 4px 10px;
    height: 26px;
    display: inline-block;
}

.card-inner-tabs span.open {
    background-color: var(--green-color);
    color: var(--secondary-color);
}

.card-inner-tabs span.closed {
    background-color: var(--red-color);
    color: var(--white-color);
}

.card-inner-tabs span:not(:last-child) {
    margin-right: 10px;
}

.card-inner-tabs p:last-child {
    margin-bottom: 0;
}

.card-inner-tabs .btn-flex {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 5px 20px 16px 20px;
}

.card-inner-tabs .btn-flex a {
    font-size: 14px;
    font-weight: 600;
    color: var(--secondary-color);
    padding: 5px 20px 5px 10px;
    border-radius: 30px;
    position: relative;
    margin-right: -10px;
    margin-bottom: -8px;
    text-decoration: underline;
}

.card-inner-tabs .btn-flex a:hover {
    color: var(--primary-color);
    text-decoration: none;
}

.card-inner-tabs .btn-flex a::after {
    content: "";
    width: 7px;
    height: 12px;
    background: url("../images/icons/arrow-right-icon-2.svg");
    transition: all 0.5s;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -4px;
}

.card-inner-tabs .btn-flex a:hover::after {
    right: 4px;
}


.job-details {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

.job-details ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.job-details ul li {
    list-style: none;
    font-size: 12px;
    color: var(--secondary-color);
    position: relative;
    padding: 3px 15px;
}

.job-details ul li::after {
    content: "";
    position: absolute;
    background: var(--blue-dark);
    width: 2px;
    height: 24px;
    top: 50%;
    right: 0;
    margin-top: -12px;
}

.job-details ul li:first-child {
    padding-left: 0;
}

.job-details ul li:last-child::after {
    width: 0px;
}

@media (max-width: 768px) {
    .job-details {
        margin-bottom: 20px;
    }

    .job-details ul {
        display: unset;
    }

    .job-details ul li {
        padding: 6px 15px;
        padding-left: 0;
    }

    .job-details ul li::after {
        top: unset;
        bottom: 0px;
        height: 2px;
        width: 100%;
        background: rgba(0, 0, 0, .10);
    }

    .job-details ul li:last-child::after {
        height: 2px;
        width: 100%;
        background: rgba(0, 0, 0, .10);
    }
}

.work-mode {
    margin-bottom: 15px;
}

.work-mode p {
    font-size: 12px;
    color: var(--secondary-color);
    margin-bottom: 0;
}

.work-mode p span {
    background-color: #b0d6f0;
    font-size: 12px;
    color: var(--secondary-color);
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 6px;
    display: inline-block;
}

.work-mode p span.open {
    background-color: var(--green-color);
    color: var(--secondary-color);
}

.work-mode p span.closed {
    background-color: var(--red-color);
    color: var(--white-color);
}

.work-mode p span:not(:last-child) {
    margin-right: 15px;
}

.content-card {
    background: var(--cardBg-color-80);
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 25px;
}

.card-benefits {
    background: #062F43;
    padding: 25px 20px;
    height: 100%;
    border-radius: 5px;
}

.card-benefits h3 {
    font-size: 28px;
    color: var(--white-color);
}

.card-benefits p {
    color: var(--white-color);
}

.card-benefits p:last-child {
    margin-bottom: 0;
}




.dashed-wp {
    background: var(--white-color);
    border: dashed 1px var(--secondary-color);
    border-radius: 5px;
    padding: 20px 25px 0px 25px;
    margin-bottom: 20px;
}

.dashed-wp .sub-title {
    background: rgba(112, 151, 171, 0.20);
    padding: 10px 25px 12px 25px;
    margin: 0 -25px 15px -25px;
}

.dashed-wp .sub-title h3 {
    font-size: 16px;
    color: var(--secondary-color);
    font-weight: 400;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .card-benefits {
        padding: 20px 15px;
    }

    .content-card {
        padding: 20px 15px;
    }

    .content-card {
        padding: 20px 15px;
    }

    .dashed-wp {
        padding: 20px 15px 0px 15px;
    }

    .dashed-wp .sub-title {
        padding: 10px 15px 12px 15px;
        margin: 0 -15px 15px -15px;
    }
}


.works-content {
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    border: solid 1px rgba(0, 0, 0, .1);
    padding: 15px 15px 65px 15px;
    border-radius: 10px;
}

.works-content img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.works-content h2 {
    font-size: 24px;
    color: var(--text-color);
    font-weight: 400;
    margin-top: 10px;
}

.works-content .btnFixed {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 15px 15px 15px;
}


.video-lg-wp {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.video-lg-wp iframe {
    width: 100%;
    height: 560px;
    border-radius: 5px;
}

.video-flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.video-sm-wp iframe {
    width: 100%;
    height: 274px;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .works-content {
        height: auto;
        position: unset;
        padding: 15px;
    }

    .works-content .btnFixed {
        position: unset;
        padding: 0;
    }

    .video-sm-wp {
        margin-bottom: 15px
    }

    .video-sm-wp:last-child {
        margin-bottom: 0px
    }

    .titleFlex {
        flex-wrap: wrap;
    }

    .titleFlex .itemsTitle {
        flex: unset;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 15px;
    }

    .titleFlex .itemsBtn {
        width: 100%;
    }

    .video-lg-wp iframe {
        height: 274px;
    }
}

.check-flex {
    display: flex;
    padding-top: 5px;
}

.check-flex>div {
    width: 30%;
}


/*custom Radio*/
.customRadio {
    margin: 2px 0;
    padding: 0;
}

.customRadio input[type=radio] {
    opacity: 0;
    height: 0;
}

.customRadio input[type=radio]+label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    padding-left: 30px;
}

.customRadio input[type=radio]+label::before {
    content: "";
    position: absolute;
    left: 2px;
    top: -2px;
    border-radius: 50%;
    border: 2px solid rgba(15, 27, 33, 0.80);
    width: 22px;
    height: 22px;
    background: #fff;
}

.customRadio input[type=radio]+label::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.customRadio input[type=radio]:checked+label::after {
    background: #026fa5;
}

.customRadio input[type=radio]:focus+label::before {
    box-shadow: 0 0px 0px 1px #6F686A, 0 0px 8px #004fc7;
    outline: 2px dotted transparent;
}


.diagram-wp {
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
}

.diagram-wp>div {
    margin-bottom: 8px;
}

.diagram-wp img {
    width: 100%;
    height: 100%;
}

.diagram-wp p {
    font-size: 14px;
    color: rgba(15, 27, 33, 0.80);
    text-align: center;
    margin: 0;
}

.diagram-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.diagram-wp .onlyMobile {
    display: none;
}

.orangeBg {
    background-color: var(--orange-color);
    padding: 0;
}

.ourPlayGroudLogos {
    background: var(--white-color);
    padding: 2% 5%;
    border-radius: 15px;
}

@media (max-width: 768px) {
    .diagram-wp .onlyWeb {
        display: none;
    }

    .diagram-wp {
        display: flex;
        justify-content: center;
    }

    .diagram-wp .onlyMobile {
        display: block;
        width: auto;
        height: auto;
    }
}

@media (max-width: 450px) {
    .diagram-wp .onlyMobile {
        width: 100%;
    }
}

.saleforce-practice-wp {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.saleforce-practice-wp .itemsCont {
    padding-left: 15px;
}

.saleforce-practice-wp .itemsCont h3 {
    font-size: 20px;
    color: var(--text-color);
}

.saleforce-practice-wp .itemsCont p {
    margin-bottom: 0;
}

@media (max-width: 480px) {
    .saleforce-practice-wp .itemsIcon img {
        height: 70px;
    }

    .saleforce-practice-wp .itemsCont {
        padding-left: 8px;
    }
}

.salesforce-services-card {
    border: solid 1px #EEEEEE;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.salesforce-services-card .img-wp {
    /* background-color: var(--primary-color); */
    border-radius: 4px 4px 0 0;
    padding: 15px 10px 12px 10px;
    text-align: center;
}

.salesforce-services-card .cont-wp {
    padding: 20px;
}

.salesforce-services-card .cont-wp h3 {
    font-size: 20px;
    color: var(--secondary-color);
}

.salesforce-services-card .cont-wp p {
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .salesforce-services-card .cont-wp {
        padding: 20px 15px;
    }
}


.gradient-card {
    width: 100%;
    height: 100%;
    position: relative;
    padding-left: 60px;
}

.gradient-card .title-flex {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.gradient-card .title-flex h2 {
    font-size: 24px;
    color: var(--secondary-color);
    margin: 0;
}

.gradient-card .title-flex .itemIcon {
    padding-right: 10px;
}


.gradient-card::before {
    content: "";
    width: 48px;
    height: 100%;
    background: #0074BD;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 30px;
}

@media (max-width: 576px) {
    .gradient-card {
        padding-left: 44px;
    }

    .gradient-card::before {
        width: 32px;
    }
}

@media (max-width: 576px) {
    .gradient-card {
        padding-left: 38px;
    }

    .gradient-card::before {
        width: 26px;
    }
}

.java-gradient::before {
    background: #0074BD;
    background: -webkit-linear-gradient(#0074BD, #EA2D2E);
    background: -o-linear-gradient(#0074BD, #EA2D2E);
    background: linear-gradient(#0074BD, #EA2D2E);
}

.nodejs-gradient::before {
    background: #68A063;
    background: -webkit-linear-gradient(#68A063, #2C8526);
    background: -o-linear-gradient(#68A063, #2C8526);
    background: linear-gradient(#68A063, #2C8526);
}

.dotNet-gradient::before {
    background: #7EC5EA;
    background: -webkit-linear-gradient(#7EC5EA, #154B8D);
    background: -o-linear-gradient(#7EC5EA, #154B8D);
    background: linear-gradient(#7EC5EA, #154B8D);
}

.python-gradient::before {
    background: #3D7DB0;
    background: -webkit-linear-gradient(#3D7DB0, #FFDF51);
    background: -o-linear-gradient(#3D7DB0, #FFDF51);
    background: linear-gradient(#3D7DB0, #FFDF51);
}

.html5-gradient::before {
    background: #E44D26;
    background: -webkit-linear-gradient(#E44D26, #793315);
    background: -o-linear-gradient(#E44D26, #793315);
    background: linear-gradient(#E44D26, #793315);
}

.angular-gradient::before {
    background: #DD0031;
    background: -webkit-linear-gradient(#DD0031, #C3002F);
    background: -o-linear-gradient(#DD0031, #C3002F);
    background: linear-gradient(#DD0031, #C3002F);
}

.reactJS-gradient::before {
    background: #00D8FF;
    background: -webkit-linear-gradient(#00D8FF, #00A9C8);
    background: -o-linear-gradient(#00D8FF, #00A9C8);
    background: linear-gradient(#00D8FF, #00A9C8);
}

.react-native-gradient::before {
    background: #00D8FF;
    background: -webkit-linear-gradient(#00D8FF, #006C80);
    background: -o-linear-gradient(#00D8FF, #006C80);
    background: linear-gradient(#00D8FF, #006C80);
}

.android-gradient::before {
    background: #32DE84;
    background: -webkit-linear-gradient(#32DE84, #198F52);
    background: -o-linear-gradient(#32DE84, #198F52);
    background: linear-gradient(#32DE84, #198F52);
}

.iOS-gradient::before {
    background: #666666;
    background: -webkit-linear-gradient(#666666, #1E1C1C);
    background: -o-linear-gradient(#666666, #1E1C1C);
    background: linear-gradient(#666666, #1E1C1C);
}

.cloud-gradient::before {
    background: #A8A7FF;
    background: -webkit-linear-gradient(#A8A7FF, #313063);
    background: -o-linear-gradient(#A8A7FF, #313063);
    background: linear-gradient(#A8A7FF, #313063);
}

.devOps-gradient::before {
    background: #4DFFC9;
    background: -webkit-linear-gradient(#4DFFC9, #0A7DA1);
    background: -o-linear-gradient(#4DFFC9, #0A7DA1);
    background: linear-gradient(#4DFFC9, #0A7DA1);
}


/* ------------------ */


.rowFlex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.rowFlex .col-left {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 15px;
    order: 1;
}

.rowFlex .col-right {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 15px;
    order: 2;
}

.rowFlex .innerCont {
    padding-right: 12px;
}

.rowFlex .innerCont p {
    line-height: 30px;
}

.rowFlex .innerImg {
    border-radius: 5px;
}

.rowFlex .innerImg img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .rowFlex .col-left {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
    }

    .rowFlex .col-right {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
    }

}

/* --------------------- */

.tabs-main-flex {
    display: flex;
    margin-bottom: 20px;
}

.tabs-main-flex .tabsItems {
    background-color: #f0f6fa;
    width: 250px;
    margin: 0;
}

.tabs-main-flex .tabsItems ul {
    margin: 0;
    padding: 0;
}

.tabs-main-flex .tabsItems ul li {
    font-size: 20px;
    color: var(--primary-color);
    list-style: none;
    padding: 20px 30px 20px 15px;
    cursor: pointer;
    text-align: right;
}

.tabs-main-flex .tabsItems ul li:hover {
    background: var(--blue-dark);
    color: var(--white-color);
}

.tabs-main-flex .tabsItems ul li.active {
    font-weight: 600;
    background: var(--blue-dark);
    color: var(--white-color);
}

.tabs-main-flex .tabsItems ul li.active::after {
    top: 15px;
    height: 4px;
}

.tabs-main-flex .tabsCont {
    background: var(--blue-dark);
    flex: 1;
    padding: 20px 25px;
}

.tabs-main-flex .tabsCont p {
    color: var(--white-color);
    line-height: 26px;
    margin-bottom: 20px;
}


@media (max-width: 768px) {
    .tabs-main-flex .tabsItems ul li {
        padding: 20px 15px;
        text-align: left;
    }

    .tabs-main-flex {
        flex-wrap: wrap;
    }

    .tabs-main-flex .tabsItems {
        width: 100%;
        margin-bottom: 10px;
    }

    .tabs-main-flex .tabsCont {
        flex: unset;
        width: 100%;
        padding: 15px 15px;
    }
}

#gototop-btn {
    display: none;
    position: fixed;
    bottom: 54px;
    right: 20px;
    border: none;
    outline: none;
    width: 70px;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
    z-index: 10;
}

@media (max-width:768px) {
    #gototop-btn {
        bottom: 20px;
    }
}

@media (max-width:450px) {
    #gototop-btn {
        width: auto;
        right: 15px;
    }

    #gototop-btn span {
        display: none !important;
    }
}

@media (max-width:350px) {
    #gototop-btn {
        bottom: 46px;
    }
}

#gototop-btn img {
    margin-bottom: 2px;
}

#gototop-btn:hover img {
    opacity: 0.8;
}

#gototop-btn span {
    display: block;
    text-align: center;
    font-size: 12px;
    color: var(--primary-color);
}


.box-flex {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.box-flex .boxItems {
    width: 50%;
    padding: 30px;
}


@media (max-width: 768px) {
    .box-flex .boxItems {
        width: 100%;
        padding: 20px 15px;
    }
}

.box-flex .boxBg1 {
    background-color: #E7F3F9;
    border-radius: 5px 0 0 0;
}

.box-flex .boxBg2 {
    background-color: #DDEEF6;
    border-radius: 0 5px 0 0;
}

.box-flex .boxBg3 {
    background-color: #DAE6EC;
    border-radius: 0 0 0 5px;
}

.box-flex .boxBg4 {
    background-color: #CADAE2;
    border-radius: 0 0 5px 0;
}

@media (max-width: 768px) {
    .box-flex .boxItems {
        width: 100%;
        padding: 20px 15px;
    }

    .box-flex .boxBg1 {
        border-radius: 5px 5px 0 0;
    }

    .box-flex .boxBg2,
    .box-flex .boxBg3 {
        border-radius: 0;
    }

    .box-flex .boxBg4 {
        border-radius: 0 0 5px 5px;
    }
}

.box-flex .boxItems h2 {
    font-size: 24px;
    color: var(--text-color);
}

.box-flex .boxItems .viewText {
    height: 145px;
    overflow-y: hidden;
    margin-bottom: 6px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.box-flex .boxItems .moreText {
    height: 275px;
    overflow-y: auto;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.box-flex .readMore {
    display: flex;
    justify-content: flex-end;
}

.box-flex .readMore button {
    font-size: 14px;
    color: var(--primary-color);
    text-align: right;
    text-decoration: underline;
    border: 0;
    background-color: transparent;
}

.box-flex .readMore button:hover {
    text-decoration: none;
}

.goToApplyForm::before {
    content: "";
    display: block;
    height: 110px;
    margin: -110px 0 0;
}


.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 20;
}

.cookie-wrapper {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    padding: 30px 5%;
    z-index: 20;
}

.cookie-wrapper h2 {
    font-size: 18px;
    color: var(--secondary-color);
    font-weight: 600;
    margin-bottom: 10px;
}

.cookie-wrapper p {
    text-align: left;
    font-size: 14px;
    color: var(--text-color);
    font-weight: 400;
    margin-bottom: 8px;
    line-height: normal;
}

.cookie-wrapper p:last-child {
    margin-bottom: 5px;
}

.cookie-wrapper a {
    font-size: 14px;
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 600;
}

.cookie-wrapper a:hover {
    text-decoration: none;
}

.cookie-wrapper .cookies-flex {
    display: flex;
    justify-content: space-between;
}

.cookie-wrapper .itemsContent {
    flex: 1;
    padding-right: 30px;
}

.cookie-wrapper .itemsBtn {
    display: flex;
    justify-content: space-between;
}

.cookie-wrapper .itemsBtn button {
    font-size: 14px;
    width: auto;
    padding: 10px 15px;
}

.cookie-wrapper .itemsBtn .btn-outline-blue {
    font-weight: 600;
    margin-right: 15px;
}

@media (max-width: 768px) {
    .cookie-wrapper {
        padding: 20px 15px 25px 15px;
    }

    .cookie-wrapper .itemsBtn button {
        width: 100%;
    }

    .cookie-wrapper .cookies-flex {
        flex-direction: column;
    }

    .cookie-wrapper .itemsContent {
        flex: unset;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
    .cookie-wrapper .itemsBtn {
        display: unset;
    }

    .cookie-wrapper .itemsBtn .btn-outline-blue {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

/*custom checkbox*/
.customcheckeBox {
    margin: 2px 0;
    padding: 0;
    margin-bottom: 5px;
}

.customcheckeBox input[type=checkbox] {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    /*&:checked + label::before {
        background: #5ac5c9;
    }*/
}

.customcheckeBox input[type=checkbox]+label {
    display: block;
    position: relative;
    padding: 0;
    font-size: 18px;
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1.5;
}

.customcheckeBox input[type=checkbox]+label:before {
    content: "";
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 22px;
    height: 22px;
    background: var(--white-color);
    border: solid 2px var(--secondary-color);
    border-radius: 5px;
}

.customcheckeBox input[type=checkbox]:checked+label::after {
    content: "";
    position: absolute;
    top: 9px;
    left: 5px;
    border-left: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    height: 6px;
    width: 12px;
    transform: rotate(-45deg);
}

.customcheckeBox input[type=checkbox]:focus+label::before {
    outline: solid 2px #5d9dd5;
    box-shadow: 0 0px 8px #5e9ed6;
}



/* ***************** */

.settingsModal,
.cookiespolicyModal,
.pupupModal {
    display: none;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
    z-index: 15;
    width: 500px;
    min-height: 190px;
    max-height: 95%;
    border-radius: 5px;
    padding: 0px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    transition: all ease-in 200ms;
    z-index: 25;
}

.settingsModal,
.cookiespolicyModal {
    width: 570px;
    height: 95%;
}

@media (max-width: 768px) {

    .settingsModal,
    .cookiespolicyModal,
    .pupupModal {
        width: 540px;
    }
}

@media (max-width: 576px) {

    .settingsModal,
    .cookiespolicyModal,
    .pupupModal {
        width: 90%;
    }
}

.settingsModal .modal-flex,
.cookiespolicyModal .modal-flex,
.pupupModal .modal-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.settingsModal .modalTitle,
.cookiespolicyModal .modalTitle,
.pupupModal .modalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.settingsModal .modalTitle h2,
.cookiespolicyModal .modalTitle h2,
.pupupModal .modalTitle h2 {
    font-size: 24px;
    margin-bottom: 0px;
}

.settingsModal .modal-flex .modalBody,
.cookiespolicyModal .modal-flex .modalBody,
.pupupModal .modal-flex .modalBody {
    margin: 0;
    padding: 0 15px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 80%;
}

.settingsModal .modal-flex .modalBody h2,
.cookiespolicyModal .modal-flex .modalBody h2,
.pupupModal .modal-flex .modalBody h2 {
    font-size: 24px;
}


.settingsModal .modal-footer,
.cookiespolicyModal .modal-footer,
.pupupModal .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.settingsModal .modalTitle a,
.cookiespolicyModal .modalTitle a,
.pupupModal .modalTitle a {
    margin-top: -3px;
    margin-right: -2px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settingsModal .modalTitle a:hover,
.cookiespolicyModal .modalTitle a:hover,
.pupupModal .modalTitle a:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 30px;
}

.settingsModal .modal-footer .btn-blue,
.cookiespolicyModal .modal-footer .btn-blue,
.pupupModal .modal-footer .btn-blue {
    width: 100%;
}

/* *************************** */

.error-page-container {
    padding: 110px 10px 90px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 600px;
}

.error-page-container>div {
    width: 100%;
}

.error-page-container h1 {
    font-size: 100px;
    color: #313063;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 12px;
}

.error-page-container h1 span {
    font-size: 28px;
    display: block;
}

.error-page-container h2 {
    font-size: 24px;
    color: var(--secondary-color);
    font-weight: 400;

}

.error-page-container p {
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 30px;
}

.error-page-container .btn-blue {
    width: 264px;
}

@media (max-width: 576px) {
    .error-page-container {
        padding: 60px 10px;
        height: 100%;
    }

    .error-page-container .btn-blue {
        width: 100%;
    }
}

/* ******************** */










/* **************************************************** */

/*------ Learning Services new page css beginning here ----*/


button.btn-white-external {
    background: var(--primary-color) url(../images/icons/external-link-white-lg-icon.svg) no-repeat right center;
    background-size: 28px 28px;
    font-size: 14px;
    color: var(--white-color);
    font-weight: 600;
    min-width: 125px;
    height: 38px;
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 5px 38px 5px 18px;
    border: 0;
}

button.btn-white-external:hover,
button.btn-white-external:focus {
    background: var(--white-color) url(../images/icons/external-link-blue-lg-icon.svg) no-repeat right center;
    color: var(--primary-color) !important;
    background-size: 28px 28px;
    border: solid 2px var(--primary-color);
}

.sectionBanner {
    position: relative;
    background: url(../images/carousel-bg.svg) no-repeat left top;
    padding: 20px 5% 4% 5%;
}

.sectionBanner .circleBg {
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(../images/circle-bg-two.svg) no-repeat right bottom;
    width: 415px;
    height: 431px;
    z-index: -1;
}

.sectionBanner::after,
.sectionLms::after {
    content: "";
    width: 100%;
    height: 352px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    background: -webkit-linear-gradient(#FFFFFF, #027684);
    background: -o-linear-gradient(#FFFFFF, #027684);
    background: linear-gradient(#FFFFFF, #027684);
    opacity: 0.20;
    z-index: -1;
}

.sectionBanner .items-lg-col-2 {
    display: flex;
    align-items: center;
}

.sectionBanner .banner-content {
    order: 1;
}

.sectionBanner .banner-content>div {
    width: 425px;
    padding-top: 40px;
}

.sectionBanner .banner-content h1 {
    font-size: 72px;
    color: var(--secondary-color);
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 12px;
}

.sectionBanner .banner-content h1 span {
    color: var(--primary-color);
    font-weight: 600;
}

.sectionBanner .banner-content .buttons-flex {
    display: flex;
}

.sectionBanner .banner-content .buttons-flex button.btn-blue {
    background: var(--primary-color) url(../images/icons/arrow-right-white-icon.svg) no-repeat right center;
    padding-right: 40px;
}

.sectionBanner .banner-content .buttons-flex button.btn-blue:hover,
.sectionBanner .banner-content .buttons-flex button.btn-blue:focus {
    background: var(--white-color) url(../images/icons/arrow-right-blue-icon.svg) no-repeat right center;
}

.sectionBanner .banner-content .buttons-flex button {
    width: 178px;
}

.sectionBanner .banner-content .buttons-flex button.btn-white {
    border: solid 1px rgba(0 0 0/.1);
}

/* .sectionBanner .banner-content .buttons-flex button:first-child{
    margin-right: 20px;
} */

.sectionBanner .banner-img {
    order: 2;
    justify-content: center;
}

.sectionBanner .banner-img img {
    width: 100%;
    height: 100%;
}

@media (max-width:1199px) {
    .sectionBanner .banner-content h1 {
        font-size: 60px;
    }
}

@media (max-width:992px) {

    .sectionBanner .circleBg {
        display: none;
    }

    .sectionBanner .banner-content {
        order: 2;
        justify-content: center;
    }

    .sectionBanner .banner-content>div {
        text-align: center;
        padding-top: 20px;
    }

    .sectionBanner p {
        text-align: center;
    }

    .sectionBanner .banner-img {
        order: 1;
    }

    .sectionBanner .banner-content .buttons-flex {
        justify-content: center;
    }
}

@media (max-width:768px) {
    .sectionBanner {
        padding: 20px 0 60px 0;
    }
}

@media (max-width:576px) {
    .sectionBanner .banner-content>div {
        width: 100%;
    }

    .sectionBanner .banner-content h1 span {
        display: block;
    }

    .sectionBanner .banner-content .buttons-flex {
        flex-wrap: wrap;
    }


    .sectionBanner .banner-content .buttons-flex button:first-child {
        margin-right: 0px;
    }

    .sectionBanner .banner-content h1 {
        font-size: 48px;
    }
}

@media (max-width:380px) {
    .sectionBanner .banner-content h1 {
        font-size: 40px;
    }

    .sectionBanner .banner-content .buttons-flex button {
        width: 100%;
    }
}

.sectionCourseCatalogue {
    position: relative;
    padding: 4% 5% 2% 5%;
}

.sectionCourseCatalogue::after {
    content: "";
    width: 100%;
    height: 540px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12%;
    background: url(../images/lineBg.svg) no-repeat top left;
    background-size: 100% auto;
    z-index: -1;
}

.course-catalogue-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    padding: 0px 30px;
    margin-bottom: 60px;
}


@media (min-width:1366.5px) {
    .course-catalogue-row {
        padding: 0px 5%;
    }
}

@media (max-width:1200px) {
    .course-catalogue-row {
        padding: 0px;
    }
}

@media (max-width:1130px) {
    .course-catalogue-row {
        margin-bottom: 30px;
    }
}

@media (max-width:679.5px) {
    .course-catalogue-row {
        margin-bottom: 0px;
    }
}

.course-catalogue-row .flex-items {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

/* @media (max-width:1130px){
    .course-catalogue-row .flex-items {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
} */

@media (max-width:1365.5px) {
    .course-catalogue-row .flex-items {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width:880px) {
    .course-catalogue-row .flex-items {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .course-catalogue-row .flex-items:last-child {
        margin-bottom: 0;
    }
}

.courseCard {
    background-color: #F8F9FF;
    border: solid 1px #D5D7E2;
    display: flex;
    border-radius: 5px;
    padding: 20px;
    height: 100%;
}

@media (max-width:768px) {
    .courseCard {
        padding: 20px 15px;
    }
}

.course-catalogue-row>div:first-child .courseCard {
    background-color: #FEFAE5;
    border: solid 1px #E3DEC1;
}

.course-catalogue-row>div:nth-child(2) .courseCard {
    margin-top: 40px;
    margin-bottom: -40px;
}

.course-catalogue-row>div:nth-child(3) .courseCard {
    background-color: #EEF7F3;
    border: solid 1px #C1DBCF;
    margin-top: 80px;
    margin-bottom: -80px;
}

.course-catalogue-row>div:nth-child(5) .courseCard {
    background-color: #E6FBFC;
    border: solid 1px #C8E4E5;
    margin-top: 40px;
    margin-bottom: -40px;
}

.course-catalogue-row>div:nth-child(6) .courseCard {
    margin-top: 80px;
    margin-bottom: -80px;
}

@media (max-width:1365.5px) {
    .course-catalogue-row>div:nth-child(2) .courseCard {
        margin-top: 50px;
        margin-bottom: -50px;
    }

    .course-catalogue-row>div:nth-child(3) .courseCard {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .course-catalogue-row>div:nth-child(4) .courseCard {
        margin-top: 50px;
        margin-bottom: -50px;
    }

    .course-catalogue-row>div:nth-child(5) .courseCard {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .course-catalogue-row>div:nth-child(6) .courseCard {
        margin-top: 50px;
        margin-bottom: -50px;
    }
}

@media (max-width:880px) {
    .course-catalogue-row>div:nth-child(2) .courseCard {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .course-catalogue-row>div:nth-child(4) .courseCard {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .course-catalogue-row>div:nth-child(6) .courseCard {
        margin-top: 0px;
        margin-bottom: 0px;
    }

}






.courseCard .item-course-content {
    flex: 1;
    position: relative;
    padding: 0px 15px 50px 0px;
}

.courseCard .item-course-content h3 {
    margin-bottom: 6px;
}

.courseCard .item-course-content p.course-text {
    color: var(--black-color-60);
    margin-bottom: 10px;
}

.courseCard .item-course-content p.details-text {
    margin-bottom: 5px;
}

.courseCard .item-course-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 12px;
}

.courseCard .item-course-content ul li {
    list-style: none;
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.5;
    padding-left: 20px;
    margin-bottom: 5px;
    position: relative;
}

.courseCard .item-course-content ul li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 52%;
    background: var(--orange-color);
    transform: rotate(48deg);
    margin-top: -3px;
}



.courseCard .item-course-content .btnFixed {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0px 15px 0px 0px;
}

.courseCard .item-course-content button {
    background: var(--primary-color) url(../images/icons/arrow-right-round-white-icon.svg) no-repeat right center;
    font-size: 14px;
    color: var(--white-color);
    font-weight: 600;
    border-radius: 30px;
    width: 100%;
    height: 38px;
    text-align: left;
    border: 0;
    padding: 5px 36px 5px 15px;
    display: flex;
    align-items: center;
}

@media (min-width:1600.5px) {
    .courseCard .item-course-content button {
        width: auto;
    }
}

@media (max-width:880px) {
    .courseCard .item-course-content button {
        width: auto;
    }
}

@media (max-width:480px) {
    .courseCard .item-course-content button {
        width: 100%;
    }
}


@media only screen and (max-width: 679.5px) and (min-width: 480.5px) {
    .courseCard .item-course-content button {
        width: auto;
    }
}

.courseCard .item-course-content button:hover,
.courseCard .item-course-content button:focus {
    background: transparent url(../images/icons/arrow-right-round-blue-icon.svg) no-repeat right center;
    color: var(--primary-color);
}


.courseCard .item-img {
    width: 140px;
    border-radius: 5px;
    position: relative;
    background-size: cover;
}

.courseCard .item-img .topIcon {
    position: absolute;
    top: 12px;
    right: 16px;
}

@media (max-width:880px) {
    .courseCard .item-img {
        width: 40%;
    }
}


@media (max-width:359.5px) {
    .courseCard .item-course-content .btnFixed {
        padding: 0px;
    }

    .courseCard {
        padding: 20px 15px;
    }

    .courseCard .item-img {
        display: none;
    }

    .courseCard .item-course-content {
        padding: 0px 0px 50px 0px;
    }
}


.courseBg1 {
    /* background-image: url(../images/course-bg-1.webp), url(../images/course-bg-1.png); */
    background-image: url(../images/course-bg-1.webp);
    background-repeat: no-repeat;
    background-position: top center;
}

.courseBg2 {
    background-image: url(../images/course-bg-2.webp);
    background-repeat: no-repeat;
    background-position: top center;
}

.courseBg3 {
    background-image: url(../images/course-bg-3.webp);
    background-repeat: no-repeat;
    background-position: top center;
}

.courseBg4 {
    background-image: url(../images/course-bg-4.webp);
    background-repeat: no-repeat;
    background-position: top center;
}

.courseBg5 {
    background-image: url(../images/course-bg-5.webp);
    background-repeat: no-repeat;
    background-position: top center;
}

.courseBg6 {
    background-image: url(../images/course-bg-6.webp);
    background-repeat: no-repeat;
    background-position: center top;
}

.approach-flow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.approach-flow>div {
    width: 30%;
    position: relative;
}

.approach-flow>div:not(:last-child) {
    padding-right: 25px;
}

.approach-flow>div:not(:last-child)::after {
    content: "";
    width: 30px;
    height: 20px;
    position: absolute;
    background: url("../images/icons/arrow-right-light-blue-icon.svg");
}

@media (min-width:768.5px) {
    .approach-flow>div:not(:last-child)::after {
        right: -15px;
        top: 50%;
        margin-top: -10px;
    }
}

.approach-flow>div>div {
    display: flex;
    margin-bottom: 12px;
}

.approach-flow .item-content {
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.approach-flow h3 {
    font-size: 24px;
    color: var(--secondary-color);
    margin-bottom: 0;
}

@media only screen and (max-width: 992px) and (min-width: 768.5px) {
    .approach-flow .item-icon img {
        width: 70px;
        height: auto;
    }

    .approach-flow h3 {
        font-size: 20px;
    }

    .approach-flow p {
        font-size: 14px;
    }

    .approach-flow .item-content {
        padding-left: 10px;
    }

    .approach-flow>div:not(:last-child)::after {
        right: -5px;
    }

}


@media (max-width:768px) {
    .sectionCourseCatalogue {
        padding: 60px 0 30px 0;
    }

    .approach-flow {
        flex-wrap: wrap;
    }

    .approach-flow>div:not(:last-child) {
        padding-right: 0px;
        margin-bottom: 40px;
    }

    .approach-flow>div {
        width: 100%;
    }

    .approach-flow>div:not(:last-child)::after {
        left: 50%;
        margin-left: -10px;
        bottom: -20px;
        transform: rotate(90deg);
    }
}

@media (max-width:576px) {
    .approach-flow h3 {
        font-size: 20px;
    }
}

.sectionLms {
    position: relative;
    padding: 2% 5% 4% 5%;
}

@media (max-width:768px) {
    .sectionLms {
        position: relative;
        padding: 30px 0px 60px 0px;
    }
}


.lms-banner-web-only {
    text-align: center;
    padding: 15px 5%;
}

.mobileOnly {
    display: none;
}

.lms-content {
    display: flex;
    flex-direction: column;
    background: url(../images/line-bg.svg) no-repeat right center;
    background-size: auto 100%;
    margin-right: -15px;
    padding: 15px 15% 15px 0px;
}

@media (max-width:450px) {
    .lms-content {
        padding: 15px 25% 0px 0px;
    }
}

.lms-content>div:not(:last-child) {
    margin-bottom: 15px;
}

.lms-content h3 {
    font-size: 22px;
    color: var(--secondary-color);
    font-weight: 600;
    margin-bottom: 6px;
}

.lms-content p {
    font-size: 16px;
    color: var(--text-color);
}

@media (max-width:1150px) {
    .lms-banner-web-only img {
        width: 100%;
        height: auto;
    }
}

@media (max-width:992px) {
    .lms-banner-web-only {
        padding: 15px 2%;
    }
}


@media (max-width:768.5px) {
    .lms-banner-web-only {
        display: none;
    }

    .mobileOnly {
        display: block;
    }
}

@media (max-width:576px) {
    .lms-content h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }
}


.sectionFeatures {
    position: relative;
    padding: 4% 5% 190px 5%;
}

.sectionFeatures::after {
    content: "";
    width: 100%;
    height: 190px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    background: #FFFFFF;
    background: url(../images/dotted-bg.svg) repeat left bottom;
    z-index: -1;
}

@media (max-width:768px) {
    .sectionFeatures {
        position: relative;
        padding: 60px 0 190px 0;
    }
}


.other-course-catalogue {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.other-course-catalogue>div {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}


@media (max-width:1199.5px) {
    .other-course-catalogue>div {
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media (max-width:767.5px) {
    .other-course-catalogue>div {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width:450px) {
    .other-course-catalogue>div {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}


.other-course-catalogue .itemsCard {
    background-color: var(--card-color);
    border: solid 1px var(--border-color);
    border-radius: 5px;
    flex: 1;
    text-align: center;
    transition: all 0.4s ease 0s;
    position: relative;
    padding: 30px 20px 85px 20px;
    height: 100%;
}

@media (max-width:768px) {
    .other-course-catalogue .itemsCard {
        padding: 30px 15px 85px 15px;
    }
}

.other-course-catalogue .itemsCard img {
    margin-bottom: 15px;
}

.other-course-catalogue .itemsCard h3 {
    font-size: 20px;
    color: var(--secondary-color);
    margin-bottom: 10px;
}

.other-course-catalogue .itemsCard p {
    font-size: 14px;
    color: var(--text-color);
}

.other-course-catalogue .itemsCard button {
    background: var(--white-color) url(../images/icons/arrow-right-round-blue-icon.svg) no-repeat right center;
    font-size: 14px;
    color: var(--primary-color);
    font-weight: 600;
    border-radius: 30px;
    width: auto;
    height: 38px;
    text-align: left;
    /* border: solid 2px var(--primary-color); */
    border: solid 2px rgba(0 0 0/.1);
    padding: 5px 40px 5px 15px;
    display: flex;
    align-items: center;
}

.other-course-catalogue .itemsCard button:hover {
    background: #EEEEEE url(../images/icons/arrow-right-round-blue-icon.svg) no-repeat right center;
}

.other-course-catalogue .itemsCard .btnFixed {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0px 15px 30px 15px;
    display: flex;
    justify-content: center;
}

.other-course-catalogue .itemsCard:hover {
    background-color: #1D7EAE;
}

.other-course-catalogue .itemsCard:hover h3,
.other-course-catalogue .itemsCard:hover p {
    color: var(--white-color);
}


.features-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.features-wrapper .contentItem {
    flex: 1;
    background: url(../images/featuresBg.svg) no-repeat right bottom;
    padding-right: 60px;
}

.features-wrapper .contentItem ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.features-wrapper .contentItem ul li {
    background: url(../images/icons/check-icon.svg) no-repeat left center;
    list-style: none;
    font-size: 16px;
    color: var(--secondary-color);
    line-height: 1.5;
    padding: 5px 0px 5px 35px;
}

.features-wrapper .contentItem ul li:not(:last-child) {
    margin-bottom: 20px;
}

.features-wrapper .itemImg {
    width: 440px;
}

.features-wrapper .itemImg img {
    border-radius: 24px;
    margin-bottom: -162px;
}

@media (max-width:1200px) {
    .features-wrapper .itemImg {
        width: auto;
    }
}


@media (max-width:992px) {

    .features-wrapper .itemContent {
        padding-right: 20px;
    }
}

@media (max-width:768px) {
    .features-wrapper .itemContent {
        flex: unset;
        background: none;
        padding-right: 0px;
        width: 100%;
        margin-bottom: 25px;
    }

    .features-wrapper .itemImg {
        width: 100%;
        text-align: center;
    }

    .features-wrapper .itemImg img {
        margin-bottom: -100px;
    }
}

@media (max-width:450px) {
    .features-wrapper .itemImg {
        padding: 0 10%;
    }

    .features-wrapper .itemImg img {
        width: 100%;
        height: auto;
    }

}

.sectionLearnFrom {
    background: #213139;
    background: -webkit-linear-gradient(to right, #213139, #27373E, #8A9498);
    background: -o-linear-gradient(to right, #213139, #27373E, #8A9498);
    background: linear-gradient(to right, #213139, #27373E, #8A9498);
    padding: 4% 5%;
}

.sectionLearnFrom .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectionLearnFrom .container>div:first-child {
    padding-right: 60px;
}

.sectionLearnFrom h2 {
    font-size: 50px;
    color: var(--white-color);
}

.sectionLearnFrom h2 span {
    display: block;
    font-weight: 400;
}

.sectionLearnFrom p {
    color: var(--white-color);
    margin-bottom: 10px;
}

.sectionLearnFrom p span {
    display: block;
    font-weight: 600;
}

.sectionLearnFrom .textMedium {
    padding-right: 0px !important;
}

.sectionLearnFrom .textMedium h2 {
    text-align: left;
    font-size: 24px;
    margin-bottom: 0;
}

@media (max-width:1199.5px) {
    .sectionLearnFrom .row-flex .items-col-2 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}



.sectionLearnFrom .container .btnWrapper {
    display: flex;
}

.sectionLearnFrom .container .btnWrapper button:first-child {
    margin-right: 15px;
}

.sectionLearnFrom .container .btnWrapper button {
    background-color: var(--black-color);
    border: solid 1px #A6A6A6;
    border-radius: 10px;
    padding: 7px 10px;
}

.sectionLearnFrom .container .btnWrapper button:hover {
    background-color: var(--secondary-color);
}


@media only screen and (max-width: 800px) and (min-width: 768.5px) {
    .sectionLearnFrom h2 {
        font-size: 42px;
    }
}

@media (max-width:768px) {
    .sectionLearnFrom .container>div:first-child {
        padding-right: 0px;
    }

    .sectionLearnFrom .container {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .sectionLearnFrom {
        padding: 60px 15px;
    }

    .sectionLearnFrom h2 {
        font-size: 42px;
        text-align: center;
        margin-bottom: 30px;
    }

    .sectionLearnFrom p {
        text-align: center;
        margin-bottom: 15px;
    }
}

@media (max-width:576px) {
    .sectionLearnFrom .container .btnWrapper {
        flex-direction: column;
        align-items: center;
    }

    .sectionLearnFrom .container .btnWrapper button:first-child {
        margin-right: 0px;
        margin-bottom: 15px;
    }
}




.sectionForm {
    background: url(../images/circle-bg-two.svg) no-repeat right bottom;
    position: relative;
    padding: 4% 5%;
}

.sectionForm::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #E3F0FB;
    background: -webkit-linear-gradient(#E3F0FB, #CFEBFB);
    background: -o-linear-gradient(#E3F0FB, #CFEBFB);
    background: linear-gradient(#E3F0FB, #CFEBFB);
    z-index: -1;
}

.sectionForm .formContent {
    max-width: 500px;
}

.sectionForm .formContent h2 {
    font-size: 34px;
    color: var(--secondary-color);
    margin-bottom: 5px;
}

.sectionForm .formContent h2 span {
    color: var(--border-blue-light);
    font-weight: 400;
}

.sectionForm .formContent h3 {
    font-size: 34px;
    color: var(--secondary-color);
    font-weight: 400;
    margin-bottom: 15px;
}

.sectionForm .formContent p {
    margin-bottom: 0;
}

.sectionForm .form-group label,
.sectionForm .form-group input {
    color: var(--secondary-color);
}

.sectionForm .custom-col-55 {
    display: flex;
    align-items: center;
}

.sectionForm .form-group button.btn-blue {
    background-color: var(--dark-blue-color);
    border-color: var(--dark-blue-color);
}

.sectionForm .form-group button.btn-blue:hover {
    background-color: transparent;
    color: var(--dark-blue-color) !important;
    border-color: var(--dark-blue-color) !important;
}

@media (max-width:992px) {
    .sectionForm .formContent {
        max-width: 100%;
    }

    .sectionForm .formContent p {
        margin-bottom: 40px;
    }
}

.section-inner-content-lms {
    width: 100%;
    padding: 25px 5% 20px 5%;
}

@media (max-width:768px) {
    .sectionForm {
        padding: 60px 0;
    }

    .section-inner-content-lms {
        width: 100%;
        padding: 20px 0px
    }
}

.lms-top-content {
    display: flex;
    justify-content: flex-start;
    padding: 0px 70px;
    margin-bottom: 50px;
}


@media (max-width:1199.5px) {
    .lms-top-content {
        padding: 0px;
    }
}

.lms-top-content .itemsContent {
    order: 1;
    flex: 1;
    padding-right: 40px;
    display: flex;
    align-items: center;
}

.lms-top-content .itemsContent p {
    font-size: 24px;
    line-height: 2.2;
    margin-bottom: 0;
}

@media (min-width:1920.5px) {
    .lms-top-content .itemsContent p {
        font-size: 28px;
        line-height: 2.2;
    }
}

.lms-top-content .itemImg {
    order: 2;
}

.lms-top-content .itemImg .img-wp {
    position: relative;
    margin-bottom: 15px;
}

.lms-top-content .itemImg .share-icons {
    padding-left: 20px;
}

.lms-top-content .itemImg button {
    width: 190px;
    font-weight: 600;
    background: var(--primary-color) url(../images/icons/external-link-white-lg-icon.svg) no-repeat right center;
    padding-right: 50px;
    position: absolute;
    right: 40px;
    bottom: 50px;
    border-color: var(--white-color);
}

.lms-top-content .itemImg button:hover,
.lms-top-content .itemImg button:focus {
    background: var(--white-color) url(../images/icons/external-link-blue-lg-icon.svg) no-repeat right center !important;
}


@media only screen and (max-width: 1050px) and (min-width: 992.5px) {
    .lms-top-content .itemsContent p {
        font-size: 20px;
        line-height: 2;
    }

    .lms-top-content .itemImg .img-wp img {
        width: 400px;
        height: auto;
    }
}

@media (max-width:992px) {
    .lms-top-content .itemsContent p {
        font-size: 22px;
        line-height: 1.8;
    }

    .lms-top-content {
        flex-wrap: wrap;
    }

    .lms-top-content .itemsContent {
        order: 2;
        flex: unset;
        padding-right: 0px;
        display: flex;
        align-items: center;
    }

    .lms-top-content .itemImg {
        order: 1;
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lms-top-content .itemImg .share-icons {
        padding-left: 0;
        margin-left: -60px;
    }
}


@media (max-width:576px) {
    .lms-top-content .itemsContent p {
        font-size: 20px;
    }

    .lms-top-content .itemImg .img-wp {
        width: 100%;
        padding: 0 5%;
    }

    .lms-top-content .itemImg .img-wp img {
        width: 100%;
        height: auto;
    }

    .lms-top-content .itemImg .share-icons {
        padding-left: 20px;
        margin-left: 0px;
    }

    .lms-top-content .itemImg {
        display: unset;
    }

    .lms-top-content .itemImg button {
        width: 170px;
        right: 6%;
        bottom: 8%;
    }
}

@media (max-width:450px) {
    .lms-top-content .itemImg .img-wp {
        padding: 0;
    }

    .lms-top-content .itemImg button {
        width: 150px;
        text-align: left;
        padding: 10px 12px;
        right: 0;
        bottom: 8%;
        padding-right: 42px;
    }

    .lms-top-content .itemImg .share-icons {
        padding-left: 0px;
    }
}

.course-card-inner {
    background-color: var(--card-color);
    border-radius: 5px;
    height: 100%;
    position: relative;
    padding: 20px 20px 75px 20px;
}

.course-card-inner h3 {
    font-size: 20px;
    color: var(--secondary-color);
}

.course-card-inner p {
    margin-bottom: 0;
}

.course-card-inner .btn-fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 20px 20px 20px;
}

@media only screen and (max-width: 991.5px) and (min-width: 768.5px) {
    .course-card-inner {
        padding: 15px 15px 75px 15px;
    }

    .course-card-inner h3 {
        font-size: 18px;
    }

    .course-card-inner p {
        font-size: 14px;
    }
}


@media (max-width:768px) {
    .course-card-inner .btn-fixed {
        display: flex;
        justify-content: flex-end;
    }

    .course-card-inner {
        padding: 20px 15px 75px 15px;
    }
}

/* @media (max-width:576px){
    .course-card-inner .btn-fixed button.btn-white-external{
        width: 100%;
    }
  } */

.show-all-wp {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.show-all-wp::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    background: rgba(0 0 0/.1);
    z-index: -1;
}

.show-all-wp>div {
    background-color: var(--white-color);
    padding: 0 10px;
}

.show-all-wp button {
    font-weight: 600;
}

.hidden-wrapper {
    margin: 0;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease 0s;
}

.section-gradient-inner {
    background: url(../images/serv-bg-bottom.svg) no-repeat bottom left;
    background-size: 100% auto;
    width: 100%;
    padding: 20px 5% 5% 5%;
    position: relative;
}

@media (max-width:768px) {
    .section-gradient-inner {
        padding: 20px 0px 15% 0px;
    }
}

.section-gradient-inner::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: -webkit-linear-gradient(#FFF0, #027784);
    background: -o-linear-gradient(#FFF0, #027784);
    background: linear-gradient(#FFF0, #027784);
    opacity: 0.10;
    z-index: -1;
}


.social-media-card {
    background-color: var(--card-color);
    border: solid 1px var(--border-color);
    border-radius: 5px;
    height: 100%;
    padding: 20px 20px 75px 20px;
    position: relative;
}

.social-media-card img {
    width: 100%;
    height: 100%;
}

.social-media-card .btn-fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 20px 20px 20px;
    display: flex;
    justify-content: center;
}

.social-media-card .btn-fixed button {
    background: var(--white-color) url(../images/icons/external-link-blue-lg-icon.svg) no-repeat right center;
    background-size: 28px 28px;
    color: var(--primary-color);
    border: solid 2px rgba(0 0 0/.1);
}

.social-media-card .btn-fixed button:hover,
.social-media-card .btn-fixed button:focus {
    background: var(--primary-color) url(../images/icons/external-link-white-lg-icon.svg) no-repeat right center;
    background-size: 28px 28px;
    color: var(--white-color) !important;
    border: solid 2px var(--primary-color);
}

@media (max-width:768px) {
    .social-media-card .btn-fixed {
        justify-content: flex-end;
    }

    .social-media-card {
        padding: 20px 15px 75px 15px;
    }
}

.social-media-card .text-wrapper {
    margin-bottom: 15px;
}

.social-media-card .textMore {
    max-height: 52px;
    overflow: hidden;
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 1.4;
    color: var(--secondary-color);
    transition: all 0.3s ease 0s;
}

.social-media-card .textMore p {
    line-height: 1.4;
    margin-bottom: 4px;
}

.social-media-card .textMore a {
    font-size: 12px;
    color: var(--primary-color);
}

.social-media-card .textMore a:hover {
    text-decoration: underline;
}

.social-media-card .textMore ul,
.social-media-card .textMore ol {
    margin: 0;
    padding: 0;
    padding-left: 18px;
}

.social-media-card .textMore ul li,
.social-media-card .textMore ol li {
    font-size: 16px;
    color: var(--secondary-color);
    margin-bottom: 4px;
}

/* .social-media-card .showMore {
    height: 90px;
    height: auto;
    overflow-y: auto;
    transition: all 0.3s ease 0s;
  } */
.social-media-card .readMore {
    display: flex;
    justify-content: flex-end;
}

.social-media-card .readMore a {
    display: block;
    font-size: 14px;
    color: var(--primary-color);
    text-decoration: underline;
}

.social-media-card .readMore a:hover,
.social-media-card .readMore a:focus {
    text-decoration: none;
}

.articles-card {
    background-color: var(--card-color);
    border: solid 1px var(--border-color);
    border-radius: 5px;
    height: 100%;
    padding: 20px 20px 80px 20px;
    position: relative;
}

.articles-card p {
    margin-bottom: 0;
}

.articles-card .btn-fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 20px 20px 20px;
}

@media only screen and (max-width: 991.5px) and (min-width: 768.5px) {
    .articles-card {
        padding: 15px 15px 80px 15px;
    }

    .articles-card h3 {
        font-size: 18px;
    }

    .articles-card p {
        font-size: 14px;
    }
}

@media (max-width:768px) {
    .articles-card {
        padding: 20px 15px 80px 15px;
    }

    .articles-card .btn-fixed {
        padding: 0px 15px 20px 15px;
    }
}


.articles-card button {
    background: var(--white-color) url(../images/icons/arrow-right-blue-icon2.svg) no-repeat right center;
    border: solid 2px rgba(0 0 0/.1);
    width: 100%;
    height: 38px;
    font-size: 14px;
    text-align: left;
    border-radius: 30px;
    padding: 5px 38px 5px 20px;
}

.articles-card button:hover,
.articles-card button:focus {
    background: var(--primary-color) url(../images/icons/arrow-right-white-icon2.svg) no-repeat right center;
    border: solid 2px var(--primary-color);
}

/*------ Learning Services new page css end here ----*/
/* ********************************************************** */







/* **************************************************** */
/*------ Inner new page css beginning here ----*/

h3.large {
    font-size: 28px;
    line-height: 1.5;
    margin-bottom: 10px;
}

@media (max-width: 576px) {
    h3.large {
        font-size: 22px;
    }
}

h3.regular {
    font-weight: 400;
}

.sectionInnerBanner {
    position: relative;
    background-color: var(--white-color);
    padding: 20px 5% 4% 5%;
}

.topInnerBanner {
    display: flex;
}

.topInnerBanner .itemTopContent {
    flex: 1;
    padding-right: 40px;
    display: flex;
    align-items: center;
}

.topInnerBanner .itemTopContent .onlyForMbl {
    display: none;
}

.topInnerBanner .itemTopContent p.xl {
    font-size: 28px;
}

.topInnerBanner .itemTopContent p.large {
    font-size: 24px;
}

.topInnerBanner .itemTopContent p.md {
    font-size: 20px;
}

.topInnerBanner .itemsImage {
    display: flex;
    align-items: center;
}


@media (max-width:992px) {
    .topInnerBanner .itemTopContent {
        flex: unset;
        padding-right: 0px;
    }

    .topInnerBanner .itemTopContent .onlyForMbl {
        display: block;
        text-align: center;
        margin-bottom: 20px;
    }

    .topInnerBanner .itemsImage {
        display: none;

    }

}

@media (max-width:768px) {
    .topInnerBanner .itemTopContent p.xl {
        font-size: 24px;
    }

    .sectionInnerBanner {
        position: relative;
        background-color: var(--white-color);
        padding: 20px 0px 60px 0px;
    }
}


@media (max-width:449.5px) {
    .topInnerBanner .itemTopContent .onlyForMbl {
        padding: 0px 6%;
    }

    .topInnerBanner .itemTopContent .onlyForMbl img {
        width: 100%;
        height: auto;
    }
}


@media (max-width:569.5px) {
    .topInnerBanner .itemTopContent .forLgImg {
        padding: 0px 6%;
    }

    .topInnerBanner .itemTopContent .forLgImg img {
        width: 100%;
        height: auto;
    }
}

.sectionCommonLightBlue {
    background: var(--card-color);
    width: 100%;
    padding: 4% 5%;
}

.itemCenter {
    display: flex;
    align-items: center;
}

.sectionCommonLightBlue h3.noMargin {
    padding-right: 30px;
    margin-bottom: 0px;
}


.steps-img-wp {
    text-align: center;
}

.steps-img-wp .forMobile {
    display: none;
}



@media only screen and (max-width: 1366px) and (min-width: 768.5px) {
    .steps-img-wp img {
        width: 100%;
        height: auto;
    }
}

@media (max-width:992px) {
    .sectionCommonLightBlue h3.noMargin {
        padding-right: 0px;
    }

    .sectionCommonLightBlue .row-flex .items-col-2 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}



@media (max-width:768px) {
    .sectionCommonLightBlue {
        padding: 60px 0px;
    }

    .steps-img-wp {
        display: flex;
        justify-content: center;
        /* padding: 0px 15%; */
    }

    .steps-img-wp .forWeb {
        display: none;
    }

    .steps-img-wp .forMobile {
        display: block;
    }
}


@media (max-width:450px) {
    .steps-img-wp img {
        width: 100%;
        height: auto;
    }

    .steps-img-wp {
        padding: 0px 8%;
    }
}

.sectionCommonWhite {
    background: var(--white-color);
    width: 100%;
    padding: 4% 5%;
}

.gradientBg {
    background: #FFFFFF;
    background: -webkit-linear-gradient(#FFFFFF, #F2F8FF);
    background: -o-linear-gradient(#FFFFFF, #F2F8FF);
    background: linear-gradient(#FFFFFF, #F2F8FF);
}

.gradientTopTo {
    background: rgb(149 226 255/0.2);
    background: -webkit-linear-gradient(rgb(149 226 255/0.2), #fff);
    background: -o-linear-gradient(rgb(149 226 255/0.2), #fff);
    background: linear-gradient(rgb(149 226 255/0.2), #fff);
}

.contentFlex {
    display: flex;
    margin-bottom: 15px;
}

.contentFlex .itemsCont {
    flex: 1;
    padding-right: 80px;
    order: 1;
}

.contentFlex .itemsGraphic {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    order: 2;
}

@media (max-width:992px) {
    .contentFlex {
        display: flex;
        flex-direction: column;
    }

    .contentFlex .itemsCont {
        flex: unset;
        padding-right: 0px;
        order: 2;
    }

    .contentFlex .itemsGraphic {
        justify-content: center;
        order: 1;
        margin-bottom: 20px;
    }

}




/* ---------------------- */

.contentLeftFlex {
    display: flex;
    margin-bottom: 15px;
}

.contentLeftFlex .leftImg {
    display: flex;
    align-items: center;
}

.contentLeftFlex .contentRight {
    flex: 1;
    padding-left: 80px;
}

@media (max-width:992px) {
    .contentLeftFlex {
        display: flex;
        flex-direction: column;
    }

    .contentLeftFlex .leftImg {
        justify-content: center;
        margin-bottom: 20px;
    }

    .contentLeftFlex .contentRight {
        flex: unset;
        padding-left: 0px;
    }

}


/* ------------------------- */






@media only screen and (max-width: 992px) and (min-width: 768.5px) {
    .customRow .items-col-3 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}



.explore-card button {
    background: var(--white-color) url(../images/icons/arrow-right-blue-icon.svg) no-repeat right center;
    width: auto;
    height: 38px;
    padding: 5px 38px 5px 15px;
}

.explore-card button:hover,
.explore-card button:focus {
    background: var(--primary-color) url(../images/icons/arrow-right-white-icon.svg) no-repeat right center;
}


@media only screen and (max-width: 1150px) and (min-width: 992.5px) {
    .explore-card button {
        width: 100%;
        font-size: 13px;
        padding: 5px 20px 5px 10px;
    }
}








@media (max-width:768px) {
    .sectionCommonWhite {
        padding: 60px 0px;
    }
}

@media (max-width:450px) {

    .contentFlex .itemsGraphic>div {
        width: 100%;
        padding: 0px 8%;
    }

    .contentFlex .itemsGraphic img {
        width: 100%;
        height: auto;
    }
}

@media (max-width:380px) {
    .explore-card button {
        width: 100%;
    }
}


@media (max-width:340px) {
    .explore-card button {
        font-size: 4.2vw;
        padding-right: 34px;
    }
}







.border-b {
    border-bottom: solid 1px rgb(0 0 0/.1);
}

.border {
    border: 0;
    border-bottom: solid 1px rgb(0 0 0/.1);
}

.bottomBg {
    background: url(../images/serv-bg-bottom.svg) no-repeat bottom left;
    background-size: auto;
    background-size: 100% auto;
    width: 100%;
    padding-bottom: 5%;
}


.image-wrapper {
    text-align: center;
}

@media (max-width:1366px) {
    .image-wrapper img {
        width: 100%;
        height: auto;
    }
}

.imgWrapper img {
    width: 100%;
    height: auto;
}

.commonSection {
    width: 100%;
    padding: 4% 5%;
}

@media (max-width:768px) {
    .commonSection {
        padding: 60px 0px;
    }
}


.marginNone h3 {
    margin-bottom: 5px;
}

.marginNone p {
    margin-bottom: 0;
}

.numbersWrapper .items-col-3 {
    display: flex;
}

.numbersWrapper .items-col-3:last-child {
    margin-bottom: 0;
}

.numbersWrapper .items-col-3:last-child p {
    margin-bottom: 0;
}

.numbersWrapper .items-col-3 h3 {
    font-size: 24px;
    margin-bottom: 6px;
}

.numbersWrapper .items-col-3 .number {
    font-size: 24px;
    color: var(--orange-color);
    line-height: 1.5;
    padding-right: 8px;
}

@media (max-width: 576px) {
    .numbersWrapper .items-col-3 h3 {
        font-size: 20px;
    }

    .numbersWrapper .items-col-3 .number {
        font-size: 20px;
    }
}

.trustedCloud .innerContFlex {
    display: flex;
}

.trustedCloud .innerContFlex .thumbImg {
    display: flex;
    align-items: center;
}

.trustedCloud .items-lg-col-2:first-child .innerContFlex .itemContent {
    padding-left: 15px;
}

.trustedCloud .items-lg-col-2:last-child .innerContFlex .itemContent {
    padding-right: 15px;
}

.trustedCloud .innerContFlex .order1 {
    order: 1;
}

.trustedCloud .innerContFlex .order2 {
    order: 2;
}



@media (max-width: 450px) {
    .trustedCloud .innerContFlex {
        flex-direction: column;
    }

    .trustedCloud .innerContFlex>div {
        width: 100%;
    }

    .trustedCloud .innerContFlex .thumbImg {
        justify-content: center;
        margin-bottom: 15px;
    }

    .trustedCloud .items-lg-col-2:first-child .innerContFlex .itemContent {
        padding-left: 0px;
    }

    .trustedCloud .items-lg-col-2:last-child .innerContFlex .itemContent {
        padding-right: 0px;
    }

    .trustedCloud .innerContFlex .order1 {
        order: 2;
    }

    .trustedCloud .innerContFlex .order2 {
        order: 1;
    }
}






/* ***************** */


.card-white {
    background: var(--white-color);
    border: solid 1px var(--border-color);
    border-radius: 5px;
    height: 100%;
    position: relative;
    padding: 20px 20px 70px 20px;
}


.card-white h2,
.card-white h3 {
    font-size: 20px;
    color: var(--secondary-color);
    font-weight: 600;
    margin-bottom: 12px;
}

.card-white .img100 img {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
}


.card-white>img {
    margin-bottom: 10px;
}

.card-white p {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    margin-bottom: 0;
}

.card-white .status {
    margin-bottom: 15px;
}

.card-white .status span {
    background-color: #b0d6f0;
    font-size: 12px;
    color: var(--secondary-color);
    line-height: 1.5;
    border-radius: 4px;
    padding: 4px 10px;
    height: 26px;
    display: inline-block;
}

.card-white .status span.open {
    background-color: var(--green-color);
    color: var(--secondary-color);
}

.card-white .status span.closed {
    background-color: var(--red-color);
    color: var(--white-color);
}

.card-white .status span:not(:last-child) {
    margin-right: 12px;
}

.cardHighlight {
    background-color: var(--card-color);
    border: solid 1px rgb(0 0 0/.1);
}

.cardHighlight h2 {
    font-size: 28px;
    color: var(--secondary-color-80);
    font-weight: 700;
}

.cardHighlight a.link-md {
    font-size: 16px !important;
}

.card-white .btn-fixed {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 20px 20px 20px;
}


.card-white p.date {
    font-size: 14px;
    color: var(--black-color-60);
    font-weight: 600;
    margin-bottom: 10px;
}


@media (max-width: 768px) {
    .card-white .btn-fixed {
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 576px) {
    .card-white {
        padding: 15px 15px 70px 15px;
    }
}


.card-white .btn-fixed .btn-blue,
.btn-rounded {
    background: var(--primary-color) url(../images/icons/arrow-right-white-icon.svg) no-repeat right center;
    font-size: 14px;
    color: var(--white-color);
    font-weight: 600;
    min-width: 160px;
    height: 38px;
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 5px 38px 5px 18px;
    border: 0;
}


.card-white .btn-fixed .btn-blue:hover,
.btn-rounded:hover,
.card-white .btn-fixed .btn-blue:focus,
.btn-rounded:focus {
    background: var(--white-color) url(../images/icons/arrow-right-blue-icon.svg) no-repeat right center;
    color: var(--primary-color) !important;
    border: solid 2px var(--primary-color);
}

@media only screen and (max-width: 992px) and (min-width: 768.5px) {

    .targetCard .row-flex .items-col-3,
    .targetCard .row-flex .items-col-2 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.solutions-wp .forMobile {
    display: none;
}

.solutions-wp .imageWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


@media (max-width: 992px) {
    .solutions-wp .forMobile {
        display: block;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .solutions-wp .imageWrapper {
        display: none;
    }
}

@media (max-width: 399.5px) {
    .solutions-wp .forMobile {
        padding: 0 6%;
    }

    .solutions-wp .forMobile img {
        width: 100%;
        height: auto;
    }

}



@media (min-width: 768.5px) {
    .listCol-2 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .listCol-2 li {
        width: 48%;
        margin-bottom: 15px;
    }
}

.section-client-details {
    background: #E5F0F6;
    width: 100%;
    padding: 1.4% 5%;
}

.section-client-details ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.section-client-details ul li {
    font-size: 16px;
    color: var(--text-color);
}

.section-client-details ul li span {
    font-weight: 600;
}

.section-client-details ul li:not(:last-child) {
    padding-right: 3%;
}


@media (max-width: 992px) {
    .section-client-details ul li {
        width: 50%;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

@media (max-width: 768px) {
    .section-client-details {
        padding: 2% 0;
    }

    .section-client-details ul li {
        width: 100%
    }

    .section-client-details ul li:not(:last-child) {
        padding-right: 0;
    }
}

@media (max-width: 480px) {
    .images-small-wp {
        padding: 0px 4%;
    }

    .images-small-wp img {
        width: 100%;
        height: auto;
    }
}




.web-aap-flex {
    padding: 0;
}

.web-aap-flex:not(:last-child) {
    padding-bottom: 4%;
}

.web-aap-flex .cont-inner-flex {
    display: flex;
    border-bottom: solid 1px rgb(0 0 0/.1);
    padding-bottom: 15px;
    margin-bottom: 12px;
}

.web-aap-flex .cont-inner-flex .item-content {
    padding-right: 120px;
}

.web-aap-flex .cont-inner-flex .item-img {
    display: flex;
    align-items: center;
}

.web-aap-flex h3 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 10px;
}

.web-aap-flex h4 {
    font-size: 24px;
    color: var(--text-color);
    font-weight: 400;
    margin-bottom: 5px;
}

.web-aap-flex h5 {
    font-size: 20px;
    color: var(--primary-color);
    margin-bottom: 5px;
}

.web-aap-flex p {
    margin-bottom: 0px;
}

@media (max-width: 768px) {
    .web-aap-flex h3 {
        font-size: 26px;
    }

    .web-aap-flex h4 {
        font-size: 18px;
    }

    .web-aap-flex h5 {
        font-size: 16px;
    }
}


/* ------for-value page h2 h3 only-------- */
.ourValuesText h2 {
    font-size: 38px;
    color: var(--secondary-color);
    font-weight: 400;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 20px;
}

.ourValuesText h2::after {
    content: "";
    width: 40px;
    height: 4px;
    background: var(--orangeLight-color);
    position: absolute;
    left: 0;
    bottom: -5px;
}

.ourValuesText h3 {
    font-size: 24px;
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 5px;
}

@media (max-width: 768px) {
    .ourValuesText h2 {
        font-size: 30px;
    }

    .ourValuesText h3 {
        font-size: 20px;
    }
}

/* -------------- */


.web-aap-flex ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

.web-aap-flex ul li {
    font-size: 16px;
    color: var(--text-color);
    line-height: 1.75em;
    position: relative;
    padding-left: 25px;
}

.web-aap-flex ul li:not(:last-child) {
    padding-right: 30px;
}

.web-aap-flex ul li::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 14px;
    background: var(--orange-color);
    transform: rotate(48deg);
    margin-top: -3px;
}

@media (max-width: 1440px) {
    .web-aap-flex ul {
        flex-wrap: wrap;
    }

    .web-aap-flex ul li:not(:last-child) {
        margin-bottom: 8px;
    }
}

@media (max-width: 992px) {
    .web-aap-flex:not(:last-child) {
        padding-bottom: 50px;
    }

    .web-aap-flex .cont-inner-flex .item-img img {
        width: 300px;
        height: auto;
    }

    .web-aap-flex .cont-inner-flex .item-content {
        padding-right: 20px;
    }

}


@media (max-width: 767.5px) {
    .web-aap-flex .cont-inner-flex .item-img {
        display: none;
    }

    .web-aap-flex .cont-inner-flex .item-content {
        padding-right: 0px;
    }
}


@media (max-width: 576px) {
    .web-aap-flex ul li {
        padding-right: 0 !important;
        width: 100%;
    }
}


@media (min-width: 767.5px) {
    .oddEvenItems>div:nth-child(even) .cont-inner-flex .item-content {
        padding-right: 0;
        padding-left: 20px;
        order: 2;
    }

    .oddEvenItems>div:nth-child(even) .cont-inner-flex .item-img {
        order: 1;
    }
}


.technologies-wp ul {
    padding: 0;
    margin: 0 -5px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.technologies-wp ul li {
    padding: 5px;
}

@media (max-width: 576px) {
    .technologies-wp ul li {
        width: 33.33%;
    }

    .technologies-wp ul li img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 380px) {
    .technologies-wp ul li {
        width: 50%;
    }
}


h3.regular-text {
    font-size: 24px;
    line-height: 2;
    font-weight: 400;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    h3.regular-text {
        line-height: 1.8;
    }
}

.itemContent {
    order: 1;
}

.imgPosition {
    text-align: center;
    order: 2;
}

@media only screen and (max-width: 1280px) and (min-width: 992.5px) {
    .image-sm-wp img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 992px) {
    .itemContent {
        order: 2;
    }

    .imgPosition {
        order: 1;
    }
}

@media (max-width: 576px) {
    .image-sm-wp img {
        width: 100%;
        height: auto;
    }
}


.contactSectionTop {
    background: var(--card-color);
    width: 100%;
    padding: 20px 5% 60px 5%;
}

@media (max-width: 768px) {
    .contactSectionTop {
        padding: 20px 0 60px 0;
    }
}

.contactImgBg {
    width: 100%;
    height: 100%;
    background-image: url(../images/contact-form-img.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 24px;
}


@media only screen and (max-width: 768px) and (min-width: 450.5px) {
    .contact-flex .items-col-3:not(:last-child) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.no-border {
    border: 0 !important;
}

.esg-inner-flex {
    display: flex;
    height: 100%;
}

.esg-inner-flex .itemLeft {
    flex: 1;
    padding: 0px 15px 0px 0px;
}

.esg-inner-flex .itemRight {
    flex: 1;
    padding: 0px 0px 0px 15px;
}

.esg-inner-flex .itemImg {
    width: 162px;
    border-radius: 24px;
    background-size: cover;
}

@media (max-width: 992px) {
    .esg-inner-flex .itemImg {
        width: 35%;
    }
}

@media (max-width: 450px) {
    .esg-inner-flex .itemImg {
        display: none;
    }

    .esg-inner-flex .itemLeft {
        padding: 0px 0px 20px 0px;
    }

    .esg-inner-flex .itemRight {
        padding: 0px 0px 0px 0px;
    }
}

.esgBg1 {
    background-image: url(../images/esg-policy-bg-1.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.esgBg2 {
    background-image: url(../images/esg-policy-bg-2.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.esgBg3 {
    background-image: url(../images/esg-policy-bg-3.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.esgBg4 {
    background-image: url(../images/esg-policy-bg-4.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.esgBg5 {
    background-image: url(../images/esg-policy-bg-5.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.esgBg6 {
    background-image: url(../images/esg-policy-bg-6.webp);
    background-repeat: no-repeat;
    background-position: center center;
}

.moreAboutLinks ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.moreAboutLinks ul li {
    margin: 0;
    padding: 0;
}

.moreAboutLinks ul li a {
    background: url(../images/icons/arrow-right-orange-icon.svg) no-repeat right center;
    font-size: 20px;
    color: var(--secondary-color);
    font-weight: 600;
    text-decoration: underline;
    padding-right: 35px;
    transition: all 0.35s ease-in-out;
}

.moreAboutLinks ul li a:hover {
    text-decoration: none;
    padding-right: 42px;
}


.ourOffices .card-white {
    padding: 30px;
    border-radius: 20px;
}

.ourOffices .card-white h3 {
    padding-bottom: 10px;
    position: relative;
}

.ourOffices .card-white h3::after {
    content: "";
    width: 50%;
    height: 2px;
    background: var(--orange-color);
    position: absolute;
    left: 0;
    bottom: 0px;
}




.zig-zag-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.zig-zag-container .col-one {
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.zig-zag-container .col-two {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

@media (max-width: 767.5px) {
    .zig-zag-container .col-two {
        display: none;
    }
}

.imagesPositon .items-col-3 {
    text-align: center;
}

/* *********************************************** */
/* ---Section What We Do css beginning here-- */


.section-what-we-do {
    background: var(--white-color);
    width: 100%;
    padding: 4% 5%;
}

@media (max-width: 768px) {
    .section-what-we-do {
        background: var(--white-color);
        width: 100%;
        padding: 60px 0;
    }
}

.section-what-we-do .contentLeft {
    position: relative;
}

.section-what-we-do .contentLeft>div {
    width: 60%;
}

.section-what-we-do h2 {
    font-size: 28px;
}

@media (max-width: 576px) {
    .section-what-we-do h2 {
        font-size: 24px;
    }
}

/* ---- */
.section-what-we-do .contentRight>div {
    width: 60%;
}

@media (max-width: 1365.5px) {

    .section-what-we-do .contentLeft>div,
    .section-what-we-do .contentRight>div {
        width: 50%;
    }
}

@media (max-width: 1170px) {

    .section-what-we-do .contentLeft>div,
    .section-what-we-do .contentRight>div {
        width: 42%;
    }
}

/* ------ */

@media (min-width: 991.5px) {
    .section-what-we-do .contentRight {
        display: flex;
        justify-content: flex-end;
        position: relative;
    }

    .section-what-we-do .contentLeft button {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .section-what-we-do .contentRight button {
        position: absolute;
        left: 20px;
        bottom: 20px;
    }

    .section-what-we-do .contentRight>div,
    .section-what-we-do .contentLeft>div {
        min-height: 266px;
    }

    .whatWeDoBg-1 {
        /* background-image: url(../images/home-product-engineering.webp), url(../images/home-product-engineering.png); */
        background-image: url(../images/home-product-engineering.webp);
        background-repeat: no-repeat;
        background-position: top right;
    }

    .whatWeDoBg-2 {
        background-image: url(../images/home-business-digital-transformation.webp);
        background-repeat: no-repeat;
        background-position: top left;
    }

    .whatWeDoBg-3 {
        background-image: url(../images/home-technology-consulting.webp);
        background-repeat: no-repeat;
        background-position: top right;
    }

    .whatWeDoBg-4 {
        background-image: url(../images/home-staff-augmentation.webp);
        background-repeat: no-repeat;
        background-position: top left;
    }

    .whatWeDoBg-5 {
        background-image: url(../images/home-learning-services.webp);
        background-repeat: no-repeat;
        background-position: top right;
    }
}

@media (max-width: 991.5px) {

    .section-what-we-do .contentLeft>div {
        width: 100%;
        padding-bottom: 0px;
    }

    .section-what-we-do .contentRight>div {
        width: 100%;
        padding-bottom: 0;
    }

    .section-what-we-do .btn-orange {
        margin-top: 25px;
    }
}

@media (max-width: 576px) {
    .section-what-we-do .btn-orange {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .section-what-we-do .btn-orange {
        font-size: 14px;
        padding: 15px;
    }
}

@media (max-width: 768.5px) {
    .onlyForWeb {
        display: none;
    }
}

/* ---Section What We Do css end here-- */
/* *********************************************** */


.bannerSectionCareer {
    /* background-image: url(../images/career-banner-img.webp), url(../images/career-banner-img.png); */
    background-image: url(../images/career-banner-img.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    padding: 20px 5% 60px 5%;
}

@media (max-width: 768px) {
    .bannerSectionCareer {
        background-image: url(../images/career-banner-mbl-img.webp);
        padding: 20px 0px 60px 0px;
    }
}

.bannerSectionCareer h1 span {
    color: var(--white-color);
}

.bannerSectionCareer .contentDetails {
    padding: 10% 0 6% 0;
}


@media (max-width: 480px) {
    .bannerSectionCareer .contentDetails {
        padding: 6% 0 3% 0;
    }
}

.bannerSectionCareer .contentDetails p {
    font-size: 20px;
    color: var(--white-color);
    font-weight: 600;
}

.images-wrapper-call-3 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    padding: 0 12%;
}

.images-wrapper-call-3>div {
    width: 33.33%;
    padding: 10px;
}


.images-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.images-wrapper>div {
    width: 25%;
    padding: 10px;
}

.card-images {
    position: relative;
}

.card-images img {
    width: 100%;
    height: auto;
    transition: all 0.35s ease-in-out;
}

.card-images img:hover {
    opacity: .8;
}

.card-images>div {
    border-radius: 0px 0px 24px 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 25px 25px 25px;
}

.card-images h3 {
    font-size: 28px;
    color: var(--white-color);
    font-weight: 700;
}

.card-images p {
    color: var(--white-color);
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .images-wrapper>div {
        width: 33%;
    }

    .card-images>div {
        border-radius: 0px 0px 20px 20px;
        padding: 0px 20px 20px 20px;
    }

    .images-wrapper-call-3 {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) and (min-width: 380.5px) {
    .card-images>div {
        padding: 0px 15px 15px 15px;
    }

    .images-wrapper {
        margin-right: -5px;
        margin-left: -5px;
    }

    .images-wrapper>div {
        width: 50%;
        padding: 5px;
    }

    .card-images h3 {
        font-size: 24px;
    }


    .images-wrapper-call-3 {
        margin-right: -5px;
        margin-left: -5px;
    }

    .images-wrapper-call-3>div {
        width: 50%;
        padding: 5px;
    }
}

@media (max-width: 380.5px) {
    .images-wrapper>div {
        width: 100%;
    }

    .images-wrapper-call-3>div {
        width: 100%;
    }
}

.testimony-flex {
    display: flex;
    justify-content: center;
    padding: 0px 8%;
}

.testimony-flex .item-content {
    max-width: 850px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.testimony-flex .item-content p {
    font-size: 18px;
    font-weight: 300;
}

.testimony-flex .item-content p span {
    font-weight: bold;
}

.testimony-flex .item-img img {
    margin-bottom: 15px;
}

@media (min-width: 768.5px) {
    .testimony-flex .scrollElem {
        min-height: 150px;
        max-height: 240px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 15px;
        margin-bottom: 5px;
    }
}

@media (max-width: 768px) {
    .testimony-flex {
        flex-direction: column;
    }

    .testimony-flex .item-content {
        padding-left: 0px;
    }

    .testimony-flex .item-content p {
        font-size: 16px;
    }

    .testimony-flex .item-img {
        text-align: center;
    }
}

@media (max-width: 450px) {
    .testimony-flex .item-img img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
}

/* ***************** */


/* ----testimony Slider css beginning here--- */
/* -----********----- */


/* Slideshow container */
.slideshow-wrapper {
    position: relative;
    margin: 0;
    padding: 0;
}

.slideshow-wrapper .itemSlides {
    display: none
}

/* Next & previous buttons */
.slideshow-wrapper a {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    font-size: 34px;
    color: #026FA5;
    transition: 0.6s ease;
    user-select: none;
    background: rgb(2 111 165/0.05);
    width: 48px;
    height: 48px;
    line-height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.slideshow-wrapper a:hover {
    background: rgb(2 111 165/0.2);
}

/* Position the "next button" to the right */
.slideshow-wrapper .next {
    right: 0;
}

@media (max-width: 992px) {
    .testimony-flex {
        justify-content: flex-start;
        padding: 0px;
    }

    .slideshow-wrapper .prev,
    .slideshow-wrapper .next {
        display: none;
    }
}

/* The dots/bullets/indicators */
.slider-main .dot {
    cursor: pointer;
    height: 16px;
    width: 16px;
    margin: 0 8px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.slider-main .dot:hover {
    background-color: #939393;
}

.slider-main .active {
    background-color: #026FA5 !important;
}

/* Fading animation */
.slider-main .fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

/* -----********----- */
/* ----testimony Slider css end here--- */


.publish-wp {
    border-bottom: solid 1px rgb(0 0 0/.1);
    margin-bottom: 15px;
}

.publish-wp p {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
}

.publish-wp p span {
    font-weight: normal;
}

@media (max-width: 768px) {
    .blog-center-img img {
        width: 100%;
        height: auto;
    }
}

.blogSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.blogSearch>div {
    display: flex;
    align-items: center;
}

.blogSearch>div>div:not(:first-child) {
    margin-left: 20px;
}

.blogSearch .groupSortBy {
    display: flex;
    align-items: center;
}

.blogSearch .groupSortBy label {
    width: 65px;
    margin-bottom: 0;
}

.blogSearch .groupSortBy select {
    width: 110px;
}

.blogSearch input {
    width: 350px;
    background: #fff url(../images/icons/search-icon.svg) no-repeat right center;
    padding-right: 38px;
}

@media (max-width: 767.5px) {
    .blogSearch {
        flex-wrap: wrap;
    }

    /* .blogSearch .form-group{
        width: 100%;
    } */
    .blogSearch input {
        width: 100%;
    }
}

@media (max-width: 667px) {
    .blogSearch {
        margin-bottom: 20px;
    }

    .blogSearch h1 {
        width: 100%;
    }

    .blogSearch>div {
        width: 100%;
    }

    .blogSearch>div>div:last-child {
        flex: 1;
    }

    .blogSearch input {
        width: 100%;
    }
}

@media (max-width: 440px) {
    .blogSearch>div {
        flex-wrap: wrap;
    }

    .blogSearch>div>div {
        width: 100%;
    }

    .blogSearch>div>div:not(:first-child) {
        margin-left: 0px;
        margin-top: 20px;
    }

    .blogSearch .groupSortBy select {
        flex: 1;
    }
}




.blogBanner {
    margin-top: 10px;
    margin-bottom: 20px;
}

.blogBanner img {
    width: 100%;
    height: auto;
}



/*------  css beginning here ----*/
/* *************************************** */



/* ----collapse css beginning here---- */

.filter-wp {
    background-color: var(--card-color);
    border: solid 1px rgb(0 0 0/.2);
    border-radius: 4px;
    margin-bottom: 20px
}

.collapse-panel {
    /* background-color: var(--white-color);
    border: solid 1px rgba(0, 0, 0, 0.10);
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0,0,0,.10);
    margin-bottom: 15px; */
    padding: 0;
    margin: 0;
}

.collapse-panel .accordion {
    cursor: pointer;
    position: relative;
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 15px 35px 15px 15px;
}

.collapse-panel .accordion:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.collapse-panel .accordion h2 {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    color: var(--secondary-color);
    transition: 0.4s;
    margin: 0;
    padding: 0;
}

.collapse-panel .accordion:after {
    content: "";
    width: 12px;
    height: 7px;
    background: url("../images/icons/arrow-dropdown.svg");
    /* background: #fff url(../images/icons/search-icon.svg) no-repeat right center; */
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -2px;
    transition: all 0.35s ease-in-out;
}

.collapse-panel .active:after {
    transform: rotate(180deg);
}

.collapse-panel .panel {
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.collapse-panel .panel>div {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 15px 10px 15px;
}

.collapse-panel .panel>div>div {
    padding-right: 30px;
}

.filter-wp .customcheckeBox input[type="checkbox"]+label {
    font-size: 14px
}

.filter-wp .customcheckeBox input[type=checkbox]:checked+label::after {
    top: 6px;
}

.filter-wp .customcheckeBox input[type="checkbox"]+label::before {
    border: solid 2px var(--black-color-60);
}


/* ***************************** */






















.titleFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.items-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}




.pb-75 {
    padding-bottom: 75px;
}

.pb-40 {
    padding-bottom: 40px;
}

.ml--16 {
    margin-left: -16px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-10 {
    padding-top: 10px;
}

.ml-5 {
    margin-left: 5px;
}

.mt-10 {
    margin-top: 15px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

@media (max-width: 576px) {
    .mb-100 {
        margin-bottom: 60px !important;
    }
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}