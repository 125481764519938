/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); */
/* @import url('http://fonts.googleapis.com/css?family=Lato:300,400,500,600,700'); */

* {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  /* --roboto-family: 'roboto', sans-serif, arial, open sans; */
  /* --font-default: 'Lato', 'Helvetica Neue', Helvetica, Arial,sans-serif;     */
  --font-default: "Open Sans", Helvetica, Arial, sans-serif;    
  --primary-color: #026fa5;
  --primary-color-60: rgba(2,111,165,0.60);
  --secondary-color: #0f1b21;
  --secondary-color-80: rgba(15,27,33,0.80);
  --text-blue: #3f8cd8;  
  --white-color: #fff;
  --black-color: #000;
  --black-color-60: rgb(0 0 0/0.6);
  --blue-dark:#013D4F;
  --dark-blue-color: #213139;

  --text-color: #373737;

  --orange-color:#ff6000;
  --orangeLight-color:#ffa000;


  --cardBg-color: #edf2f5;
  --cardBg-color-80: rgba(237,242,245,0.80);

  --gray-light:#f4f4f4;
  --border-blue-light:#7097ab;
  --border-color: #D1E4F2;
  --card-bg: #dde4f8;
  --contact-card-bg: #f0f4f7;

  --red-color: #D01C24;
  --green-color: #00E910;

  --card-color: #F0F6FA;
  --footer-bg-color:#EEF6FD;


  
  --light-blue: #00c6ff;
  --white: #fff;
  --blue-light: #80bdff;
  --gray-bg:#b3b3b3;
  --light-red:#FF6666;
  



  --color-heading: #202942;
  --light-white: #f7f7f7;
  --whitesmoke: #f5f5f5;
  --blue-hover: #015f8f;
  --navy-blue: #1B1464;
 
  --blue-grey-color: #8092a0;
  

  --input-bg-color: #eef3fa;

  --box-shadow-primary: 1px 4px 20px -2px rgba(0, 0, 0, 0.1);
  --box-shadow-secondary: 0 20px 50px rgba(0, 0, 0, 0.1);
  --box-shadow-regular: 0px 2px 12px 0px #e7e7e7;
  --box-shadow-extra: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  --bg-gray: #edf5ff;
  --bg-gradient: linear-gradient(45deg, #02ebad 0%, #5a57fb 50%);
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));

}


html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 16px;
  height: 100%;
  margin: 0px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: var(--white-color);
  font-family: var(--font-default);
  font-size: 16px;
  color: var(--secondary-color);
  line-height: 2;
  height: 100%;
  padding: 0;
  margin: 0;
}
img {
  vertical-align: middle;
}
a,
a:focus,
a:active,
a:visited {
  text-decoration: none;
}
a {
  color: var(--light-blue);
  font-size: 14px;
}

button {
  border-radius: 5px;
  cursor: pointer;
}
button:focus,
input:focus,
textarea:focus,
select:focus,
a:focus {
  border-color: var(--blue-light);
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

input[type="text"],
input[type="email"],
select {
  border: 0px solid;
  border-color: transparent transparent rgb(191, 211, 197) transparent;
  font-size: 16px;
}


/*---browser Scrollbar css beginning here */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgray white;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-moz-scrollbar {
  width: 6px;
  height: 6px;
}
/* *::-webkit-scrollbar-track {
  background: white;
}

*::-moz-scrollbar-track {
  background: white;
} */
*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  /* border: 3px solid white; */
}
*::-moz-scrollbar-thumb {
  background-color: lightgray;
  -moz-border-radius: 20px;
  border-radius: 20px;
  /* border: 3px solid white; */
}
/*---browser Scrollbar css end here */



/* Zoom Animation for popup */

.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}
@-webkit-keyframes animatezoom {
  from {
      -webkit-transform: scale(0)
  }
  to {
      -webkit-transform: scale(1)
  }
}
@keyframes animatezoom {
  from {
      transform: scale(0)
  }
  to {
      transform: scale(1)
  }
}







/**scrollable elements*/

.scroll-elem {
  opacity: 0;
  transition: opacity 500ms;
}

.scroll-elem.scroller {
  opacity: 1;
}

.scroller.fade-in {
  animation: fade-in 1.2s ease-in-out both;
}

.scroller.fade-in-bottom {
  animation: fade-in-bottom 1s ease-in-out both;
}

.scroller.slide-left {
  animation: slide-in-left 1s ease-in-out both;
}

.scroller.slide-right {
  animation: slide-in-right 1s ease-in-out both;
}

/* ------------------------------------- */

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);      
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
